

.custonBtn { 

  background-color: #ffffff;
  padding: 0px 20px;
  display: inline-flex;
  text-align: center;
  height: 60px;
  line-height: 60px;
  border-radius: 30px;
  box-shadow: 0px 6px 9px #00000017;
  border: 1px solid #2B2A29;
  text-decoration: none;
  color: #2B2A29;
  text-transform: uppercase;
  font-weight: 500;
  margin-top: 40px; 
  
  transition: all 0.3s;
}
.custonBtn span{
  display: inline-block; 
  align-self: center;
  padding-left: 0.625vw;
  vertical-align: 1px;
}
.custonBtn span svg {
  stroke: #111111;
}
.custonBtn:hover{
  transition: all 0.3s;
  background-color: #8c1211;
  color: #ffffff;
}
.custonBtn:hover span svg{
  stroke: #ffffff;
}


/* heading css */

.AboutArea h3{
  font-size: 170px;
}

img.navbar-toggler-icon {
  background-image: none;
}





/* .gulamHeaderwrapper {
  padding: 22px 0px !important;
} */
.homeBanner .bannerContent .bottomArea button span svg {
  width: 24px;
}
.newClass.header .gulamHeaderwrapper{
  padding: 0px 0px !important;
}
.newClass.header .navbar a.navbar-brand img {
  width: 120px;
}

.gulab-header-wrapper {
  width: 100%;
  align-items: center;
  justify-content: end;
  column-gap: 20px;
  position: relative;
}
.gulab-header-wrapper a{
  font-size: 1.042vw !important;
  color: #ffffff;
  padding: 2.083vw 0vw;
}
.gulab-header-wrapper .nav-item{
  position: relative;
}
.gulab-header-wrapper .dropdown-menu a {
  width: 56%;
  color: #1e1e1e;
  font-weight: 500;
  font-size: 1.24vw !important;
  padding: 0.521vw;
  line-height: normal;
  cursor: pointer;
  position: relative;
}
.homeBanner .bannerContent .mainHeading h1 {
  font-family: "EB Garamond", serif;
  font-size: 11.474vw;
  font-weight: 900;
  color: #fdf6ee;
  line-height: 10.211vw;
  padding-bottom: 0vw;
}
.homeBanner .bannerContent .bottomArea button{
  margin-left: 0;
  font-size: 16px;
}
/* .AboutArea {
  padding: 5.313vw 0vw 5.771vw 0vw;
} */
.aboutContent.aboutWrapper {
  padding-top: 5.99vw;
}
.AboutArea .aboutWrapper h2 {
  padding-bottom: 2.865v !important;
}
.AboutArea .aboutWrapper p {
  height: auto;
  font-size: 1.25vw;
  color: #1e1e1e;
  font-weight: 500;
  max-width: 38.54vw;
  padding-bottom: 0vw;
}
.AboutArea button{
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
}
.AboutArea button span svg{
  stroke: #231F20;
}
.AboutArea button:hover {
  background: #8c1211;
  color: #fff;
  border-color: #8c1211;
}
.AboutArea button:hover span svg{
  stroke: #ffffff;
}

.gulamMainprodigies {
  width: 100%;
  margin-top: -80px;
}
.UGMKArea.ourCourses h3 {
  font-size: 180px !important;
}
.testimonialHeading h3{
  font-size: 180px !important;
}
.gulamMainprodigies h2 {
  font-size: 5.25vw;
}

.gulamMainstudent {
  width: 100%;
  margin-top: -90px;
}
.OurCoursestext {
  padding-bottom: 0;
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: space-between;
}
.gulamMainstudent .OurCoursestext .UGMKpera {
  width: 66%;
}
.prodigiesHeading {
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: space-between;
}

.prodigiesHeading .UGMKpera {
  width: 70%;
}
.howItWorks .stepsBox .stepDetail {
  width: 34.583vw;
  padding-left: 0;
}
.howItWorks .stepsBox li::before {
  top: 7.938vw;
  left: 4.394vw;
}
.howItWorks h3 {
  top: -0.219vw;
  font-size: 180px;
}
.howItWorks .leftContent h2{
  font-size: 5.25vw;
  line-height: 5.771vw;
}

section.UGMKArea.ourCourses {
  padding-top: 0;
}
.CoursesOuter .coursesBox {
  background: #f6efe2;
  padding: 0;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: scale(1);
  position: relative;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  position: relative;
}
.CoursesOuter .countBox {
  font-weight: 600;
  font-size: 6.25vw;
  line-height: normal;
  color: #d5b275;
  position: relative;
  top: 0;
  left: 14px;
  opacity: 60%;
  display: inline;
}


.UGMKArea.ourCourses .cpadR {
  padding-right: 0;
}
.react-multi-carousel-list{
  padding: 0;
}

.ugmkWrapper .UGMKpera p{
  width: 100%;
}
.spiritualContent p{
  width: 90%;
  font-size: 2.813vw !important;
  color: #1E1E1E;
  line-height: 1.2;
}

.AboutmainWrapper {
  width: 100%;
  display: flex;
  padding-left: 0vw;
}
.AboutmainWrapper .AboutImg img{
  width: 100%;
}
.AboutmainWrapper .AboutImg{
  width: 56%;
}
.spiritualContent h2 {
  font-size: 5.208vw;
  line-height: 7.104vw;
  padding-bottom: 0;
}
.courseImg3 {
  position: relative;
  margin-top: -11.146vw;
  text-align: right;
  left: 10%;
}
.courseImg3 img {
  height: 76.43vh !important;
  width: 32vw !important;
}
.topPad1{
  margin-top: 10.208vw;
}



.homeBanner .bannerContent .bottomArea button span svg{
  stroke: #231F20;
}
.homeBanner .bannerContent .bottomArea button:hover span svg{
  stroke: #ffffff;
}
.ugmkWrapper {
  padding: 100px 0px
}
.ugmkWrapper h3{
  font-size: 180px;
  line-height: 7.813vw;
}
/* contact page css */

section.ContactBanner{
  margin-top: -9.51vw;
}
.WriteToUs .WriteInner textarea:focus{
  outline: none;
  box-shadow: none;
}
.collapse:not(.show) {
  display: block !important;
}
.FAQcontent .panel-title > .collapse:not:before{
  content: "\f068" !important;
}
.panel-title a[aria-expanded="true"]:before{
  content: "\f068" !important;
}

.custom-container{
  max-width: 87.5vw !important;
}
.ugmkWrapper .ViewAll button span svg {
  stroke: #231F20;
}
.ugmkWrapper .ViewAll button:hover span svg {
  stroke: #ffffff;
}
.UGMKArea .UGMKsliderArea{
  padding-left: 6.5vw;
}
.howItWorks .leftContent button span svg {
  stroke: #231F20;
}
.howItWorks .leftContent button:hover span svg {
  stroke: #ffffff;
}

.howItWorks .stepsBox li:nth-child(2) .circleBox img{
	width: 70%;
}
.OurCoursestext button span svg{
  stroke: #231F20;
}
.OurCoursestext button:hover span svg{
  stroke: #ffffff;
}

.StudentProgram {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 5.208vw;
  column-gap: 130px;
}

.StudentProgram .CoursesOuter{
  width: 45%;
  padding-bottom: 40px;
}
.CoursesOuter.topPad {
  margin-top: 5.208vw;
}
.CoursesOuter .courseImg{
  top: 0;
  text-align: center;

}


/* .CoursesOuter .courseImg img {
  width: 112%;
} */
.ugmkWrapper .ViewAll button span svg, .AboutArea button span svg{
  width: 24px;
}

.howItWorks .leftContent button span svg{
  width: 24px;
}
.OurCoursestext button span svg{
  width: 24px;
}
.gulamFooterwrapper {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.socialMedia {
  width: 100%;
}
.socialMedia .FooterLogo img {
  width: 170px;
}
.FooterLinks{
  width: 100%;
}





/* about page */

.GulamIntroductionBanner {
  width: 100%;
  padding: 100px 0px 0px;
  background-color: #FAF6F0;
}
.GulamIntroductionBanner .title{
  position: relative;
}
.GulamIntroductionBanner .title h3 {
  font-size: 180px;
  line-height: 150px;
  font-family: "Karla", sans-serif;
  font-weight: 800;
  color: #D5B376;
  opacity: 10%;
}
.GulamIntroductionBanner .title h2 {
  padding-left: 124px;
  position: absolute;
  bottom: -50px;
  font-size: 5.25vw;
}
.GulamAboutInner {
  width: 100%;
  display: flex;
  padding-left: 124px;
}
.GulamAboutInner .IntroductionText {
  width: 32.24vw;
  height:46.67vh; 
  margin-top: 50px;
  
}
.GulamAboutInner .introImg{
  width: 60%;
}
.GulamAboutInner .IntroductionText p {
  font-size: 24px;
  line-height: 35px;
  color: #1e1e1e;
  margin-bottom: 0;
  padding-bottom: 30px;
  font-weight: 500;
}


.OurGurusArea .title {
  position: relative;
}


.guruTeamWrapper p {
  font-size: 24px;
  color: #1e1e1e;
  margin: 0px;
  font-weight: 500;
  padding-bottom: 60px;
  margin-right: 125px;
}
.OurGurusArea .GurusDetail {
  padding-bottom: 80px;
  width: 100%;
  display: flex;
  column-gap: 60px;
  justify-content: space-between;
}
.OurGurusArea .GurusDetail .GurusContentLeft {
  width: 60%;
  padding-right: 40px;
  padding-top: 45px;
}
.OurGurusArea .GurusDetail .GurusImg {
  width: 29%;
  position: relative;
  z-index: 1;
}
.OurGurusArea .GurusDetail .left-img:before{
  width: 480px !important;
  right: inherit;
  left: 0;
  height: 92% !important;
}
.OurGurusArea .GurusDetail .right-img{
  width: 50% !important;
}

.OurGurusArea .GurusContentLeft h4 {
  color: #787878;
  padding-bottom: 30px;
  font-size: 34px;
}
.OurGurusArea .GurusContentLeft p{
  line-height: 36px;
}
.GurusContentLeft.paddTop {
  padding-top: 60px;
}
section.GetinTouchContact {
  width: 100%;
  padding: 100px 0px 60px;
  background-color: #faf6f0;
}
.ContactTItle {
  width: 100%;
  text-align: center;
  position: relative;
}
.ContactTItle h3{
  font-size: 180px;
    line-height: 150px;
    font-family: "Karla", sans-serif;
    font-weight: 800;
    color: #D5B376;
    opacity: 10%;
}
.ContactTItle h2 {
  position: absolute;
  left: 0;
  right: 0;
  top: 56px;
}
section.GetinTouchContact .locationOuter {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 140px;
}
section.GetinTouchContact .locationOuter .locationBox {
  width: 33%;
  text-align: center;
  padding: 30px; 
  border-right: 1px solid #1111112b;
}
section.GetinTouchContact .locationOuter .locationBox:nth-child(3){
  border-right: 0;
}
section.GetinTouchContact .locationOuter .locationBox .IconBox img{
  width: 34px;
}
section.GetinTouchContact .locationOuter .locationBox .IconBox {
  width: 23vw;
  height: 100px;
  line-height: 97px;
  box-shadow: 0px 6px 12px #00000017;
  background-color: #F6EFE2;
  border-radius: 50%;
  margin: 0 auto;
}
section.GetinTouchContact .locationOuter .locationBox .IconBox img{
  width: auto;
}
section.GetinTouchContact .locationOuter .locationBox h4{
  padding-top: 30px;
  font-size: 1.87vw;
  font-weight: 600;
}
section.GetinTouchContact .locationOuter .locationBox p {
  font-size: 24px;
  padding-top: 15px;
  margin-bottom: 0;
  color: #1e1e1ec2;
  font-weight: 500;
}
section.GetinTouchContact .locationOuter .locationBox p a{
  color: #8C1211;
}
.LifeUGMKArea h2.heading {
  margin-top: -32px !important;
}



.BannerImg{
  position: relative;
}
.innerbannerContent {
  position: absolute;
  left: 0;
  right: -0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}
.innerbannerContent h2{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: "EB Garamond", serif;
  font-size: 100px;
  font-weight: 900;
}
.breadcrumb-wrapper ul {
  display: flex;
  list-style: none;
  align-items: center;
  column-gap: 14px;
  padding: 0;
}
.breadcrumb-wrapper ul li {
  color: #ffffff;
}
.breadcrumb-wrapper ul li a {
  color: #faf3eb9e;
  text-decoration: none;
}
.innerbannerContent .mob-content{
  display: none;
}
.WriteToUs .WriteInner{
  padding: 75px 60px !important;
}
.WriteToUs .WriteInner h2{
  font-size: 60px;
}
.WriteToUs .WriteInner input, .WriteToUs .WriteInner select{
  font-size: 24px;
  height: 80px;
  color: #111111;
}
.WriteToUs .WriteInner input, .WriteToUs .WriteInner select option{
  color: #111111;
}
.WriteToUs .WriteInner textarea{
  font-size: 24px;
    height: 80px;
}
section.WriteToUs.padd-btm {
  padding-bottom: 120px;
}
.WriteToUs .WriteInner input::-ms-input-placeholder { 
  color: #111111;
}
.WriteToUs .WriteInner input::placeholder {
  color: #111111;
}

.WriteToUs .WriteInner textarea::-ms-input-placeholder { 
  color: #111111;
}
.WriteToUs .WriteInner textarea::placeholder {
  color: #111111;
}
.WriteToUs .WriteInner input, .WriteToUs .WriteInner select:focus{
  outline: none;
  box-shadow: none;
  background-color: transparent;
}
.WriteToUs .WriteInner input, .WriteToUs .WriteInner input:focus{
  outline: none;
  box-shadow: none;
  background-color: transparent;
}

.multi-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.multi-btn .custonBtn {
  margin-top: 0px;
}
.ViewAll.Cviewmob .custonBtn {
  display: none;
}
/* =====================RESPONSIVE ============================= */



@media (max-width: 1600px) {
  .CoursesOuter .courseImg {
    top: -4vw;
    left: -5vw;

  }
  .CoursesOuter .courseImg {
width: 42.32vw;
  }
  .aboutmenu {
    top: 150px !important;
  }
  .homeBanner{
    height: 900px;
  }
  .AboutArea h3, .ugmkWrapper h3, .howItWorks h3 {
    font-size: 140px;
  }
  .UGMKArea.ourCourses h3, .testimonialHeading h3 {
    font-size: 140px !important;
  }
  .PrivacyPolicy{
    padding: 60px 0px 60px;
  }
  .PrivacyPolicy .PrivacyContent .privacyHeading{
    font-size: 30px;
  }
  .PrivacyPolicy .PrivacyContent .PrivacyRightContent{
    font-size: 18px;
  }
  .gulab-header-wrapper {
    column-gap: 30px;
  }
  .TermuseContent{
    padding: 60px 0px 25px;
  }
  .FAQcontent{
    padding: 50px 0px 60px;
  }
  .FAQcontent .panel.panel-default {
    padding-bottom: 30px;
    padding-top: 30px;
}
.ContactTItle h3{
  font-size: 140px;
}
section.GetinTouchContact .locationOuter {
  margin-top: 40px;
}
section.GetinTouchContact .locationOuter .locationBox p {
  font-size: 20px;
}
.customMenu .dropdown-menu a:after{
  top: 12px;
}
.GulamIntroductionBanner .title h3 {
  font-size: 140px;
}
.awardArea h3{
  font-size: 140px;
}
.GulamAboutInner .IntroductionText p{
  font-size: 19px;
}
.OurGurusArea .GurusContentLeft p {
  line-height: 30px;
  font-size: 20px;
}
.guruTeamWrapper p{
  font-size: 20px;
}
.howItWorks .stepsBox li::before {
  left: 4.2vw;
}
.CoursesOuter .coursesBox{
  width: 37.32vw;
  height: 69.91vh;
}
}

@media (max-width: 1500px) {
  .StudentProgram .CoursesOuter {
    width: 43%;
  }
  .CoursesOuter .courseImg img {
    height: auto;
    width: 42.32vw;
  }
  .CoursesOuter .courseBottom {
    padding: 3.042vw 0px;
  }
  .UGMKArea .UGMKsliderArea .imgBox img {
    height: 63.24vh;
    width: 390px;
  }
}
@media (max-width: 1440px) {
  .UGMKArea .UGMKsliderArea .imgBox img {
    height: 63.24vh;
    width: 370px;
  }
  .CoursesOuter .courseImg {
    top: -4vw;
    left: -5vw !important;

  }
  .CoursesOuter .courseImg {
width: 42.32vw;
  }
  .newClass.header .gulamHeaderwrapper {
    padding: 0px 0px;
  }
  section.GetinTouchContact .locationOuter .locationBox p{
    font-size: 18px;
  }
  .FAQcontent .panel-title a {
    font-size: 2.363vw;
}
.FAQcontent .panel-body {
  font-size: 1.846vw;
}
.AboutArea h3, .ugmkWrapper h3, .howItWorks h3{
  font-size: 124px;
}
.UGMKArea.ourCourses h3 {
  font-size: 120px !important;
}

}
@media (max-width: 1366px) {
  .UGMKArea .UGMKsliderArea .imgBox img {
    height: 63.24vh;
    width: 340px;
  }
  .gulamMainprodigies {
    width: 100%;
    margin-top: -65px;
  }
  .GulamIntroductionBanner .title h2{
    padding-left: 100px;
  }
  .GulamAboutInner{
    padding-left: 100px;
  }
  .GulamAboutInner .IntroductionText{
    margin-top: 12px;
  }
  .GulamIntroductionBanner .title h2{
    bottom: -21px;
  }
  .GulamAboutInner .IntroductionText p, .OurGurusArea .GurusContentLeft p, .guruTeamWrapper p{
    font-size: 0.8vw;
    line-height: 1.2;
    font-weight: 600;

  }
  .AwardSliderArea .AwardSlide img{
    width: 100%;
  }
  
.gulab-header-wrapper .dropdown-menu a{
  width: 70;
}
}

@media (max-width: 1280px) {
  .UGMKArea .UGMKsliderArea .imgBox img {
    height: 63.24vh;
    width: 22.45vw;
  }
  .homeBanner{
    height: 745px;
  }
  .testimonialHeading h3{
    top: -0.594vw !important;
  }
  .footerSection .FooterLinks h4{
    font-size: 1.45vw !important;
  }
  .footerSection .FooterLinks a{
    font-size: 1.20vw !important;
  }
  .footerSection .FooterLinks .addressBox{
    font-size: 1.20vw !important;
  }

  .guruTeamWrapper h2{
    font-size: 5.25vw;
  }
  .LifeUGMKArea .UGMKheading h2{
    font-size: 5.25vw;
  }
  .LifeUGMKArea{
    padding: 30px 0px;
  }
  section.GetinTouchContact .locationOuter .locationBox p {
    font-size: 15px;
}
.ContactTItle h2{
  font-size: 6.25vw;
    line-height: normal;
}
.ContactTItle h3{
  font-size: 100px;
}

.howItWorks .leftContent button{
  width: 12.063vw;
    height: 4.125vw;
    border-radius: 30px;
}
.AboutArea h3, .ugmkWrapper h3, .howItWorks h3{
  font-size: 114px;
}
.UGMKArea.ourCourses h3 {
  font-size: 100px !important;
}
}

@media (max-width: 1199px) {
  .ugmkWrapper .UGMKpera p {
    width: 100%;
  }
  .UGMKArea.ourCourses {
    padding-top: 2.729vw;
  }
  .ourCourses .UGMKpera p{
    width: 100%;
  }
  .howItWorks .stepsBox li::before {
    left: 4.294vw;
  }
  .StudentProgram{
    column-gap: 0;
  }
  .UGMKArea .UGMKsliderArea .imgBox img {
    height: 385px;
    width: 290px;
  }

  .navbar a.navbar-brand img {
    width: 28.27vw;
  }
  .gulamMainstudent {
    width: 100%;
    margin-top: -70px;
  }
  .GulamIntroductionBanner .title h3{
    font-size: 130px;
  }
  section.GetinTouchContact .locationOuter{
    flex-wrap: wrap;
  }
  section.GetinTouchContact .locationOuter .locationBox{
    padding: 30px 0px;
  }
  .newClass.header .gulamHeaderwrapper {
    padding: 15px 0px !important;
  }
  .howItWorks .stepsBox .circleBox img {
    width: 90%;
}
.testimonialHeading h3 {
  font-size: 88px !important;
}
.TestimonialArea .testimonialHeading h2 {
  font-size: 6.25vw;
  line-height: 6.427vw;
}
}

@media (max-width: 1024px) {
  .AboutArea h3, .ugmkWrapper h3, .howItWorks h3 {
    font-size: 90px;
}
.gulamMainprodigies h2{
  padding-bottom: 0;
}
.gulamMainprodigies {
  margin-top: -45px;
}
  .TestimonialArea{
    padding-top: 0 !important;
  }
  .GulamIntroductionBanner .title h3 {
    font-size: 90px;
  }
  .GulamAboutInner{
    flex-direction: column-reverse;
    max-width: 87.5vw;
    margin: 0 auto;
    padding-left: 0;
  }
  .GulamAboutInner .IntroductionText{
    width: 100%;
  }
  .GulamAboutInner .introImg {
    width: 100%;
  }
  .GulamIntroductionBanner {
    padding: 50px 0px 0px;
  }
  .GulamIntroductionBanner .title h2 {
    bottom: -5px;
  }
  .OurGurusArea .GurusDetail{
    flex-direction: column-reverse;
  }
  .OurGurusArea .GurusDetail .GurusContentLeft {
    width: 100%;
    padding-right: 0;
    padding-top: 14px;
  }
  .guruTeamWrapper p{
    margin-right: 0;
  }
  .OurGurusArea .reservse {
    flex-direction: column;
  }
  .awardArea h3{
    top: 20px;
  }
  .AwardSliderArea .AwardSlide img{
    height: auto;
  }
  .AwardSliderArea .AwardSlide h4{
    font-size: 20px;
  }
  .LifeUGMKslider {
    padding-top: 25px;
  }
  .ContactTItle h3{
    font-size: 95px;
  }
  section.GetinTouchContact .locationOuter .locationBox{
    width: 100%;
    border-right: 0;
    border-bottom: 1px solid #1111112b;
  }
  .UGMKArea.ourCourses h3 {
    font-size: 90px !important;
}
}

@media (max-width: 991px) {
  .CoursesOuter .courseImg {
    top: -4vw;
    left: -5vw !important;

  }
  .navbar-toggler:focus, .navbar-toggler:active {
    outline: none !important;
    border: none !important;
    box-shadow: none !important; /* Removes any shadow effect as well */
  }
  .CoursesOuter .courseImg {
width: 42.32vw;
  }
  .spiritualContent h2{
    font-size: 4.208vw !important; 
    line-height: 5.104vw;
  }
  .spiritualContent p {
    width: 100%;
    font-size: 2.113vw !important;
    line-height: 2.854vw;
    padding-top: 0.833vw;
  }
  .homeBanner .bannerContent .bottomArea p {
    font-size: 2.018vw;
    line-height: 2.563vw;
  }
  .homeBanner .bannerContent .bottomArea {
    width: 100%;
  }
  .socialMedia .FooterLogo img {
    width: 115px;
  }
  .gulamFooterwrapper{
    flex-wrap: wrap;
  }
  .socialMedia {
    width: 48%;
  }
  .FooterLinks {
    width: 48%;
  }
  .footerSection .FooterLinks h4 {
    font-size: 1.620vw !important;
    margin-bottom: 2.042vw !important;
  }
  .footerSection .socialMedia ul{
    padding: 0;
  }
  .footerSection .FooterLinks a {
    font-size: 1.620vw !important;
  }
  .footerSection .FooterLinks .addressBox {
    font-size: 1.620vw !important;
  }
  .footerSection .socialMedia li {
    list-style: none;
    display: flex;
    column-gap: 10px;
  }
  .footerSection .socialMedia li a{
    width: 3.916vw !important;
    height: 3.916vw !important;
  }
  .footerSection .socialMedia li img {
    width: 1.671vw;
    height: 1.673vw;
  }
  .GulamIntroductionBanner .title h2{
    padding-left: 75px;
  }
  .GulamAboutInner{
    padding-left: 0px;
  }
  .GurusContentLeft.paddTop{
    padding-top: 0;
  }
  .LifeUGMKArea .UGMKheading h3{
    top: -25px;
  }
  section.GetinTouchContact{
    padding: 50px 0px;
  }
  section.GetinTouchContact .locationOuter{
    margin-top: 20px;
  }
  section.ContactBanner {
    margin-top: -10.51vw;
}

.innerbannerContent .mob-content{
  display: block;
  text-align: center;
}
.innerbannerContent .dekstop-content {
  display: none;
}
.PrivacyPolicy .PrivacyContent .PrivacyRightContent{
  padding-left: 0;
}
.WriteToUs .WriteInner h2 {
  font-size: 50px;
}
.WriteToUs .WriteInner input, .WriteToUs .WriteInner select {
  font-size: 20px;
  height: 65px;
}
.WriteToUs .WriteInner textarea {
  font-size: 20px;
  height: 65px;
}
.OurGurusArea .GurusDetail .GurusImg{
  width: 31%;
  margin: auto;
}
.OurGurusArea .GurusDetail .GurusImg:before{
  width: 350px;
  height: 92%;
  left: -45px;
}
.awardArea h3 {
  font-size: 90px;
}
.GulamAboutInner .IntroductionText p{
  line-height: 30px;
  padding-bottom: 11px;
}
.GulamAboutInner .IntroductionText{
  padding-right: 0;
}
.OurGurusArea .GurusContentLeft h2{
  font-size: 30px;
  line-height: 40px;
}
.GurusContentLeft h4 {
  font-size: 24px;
}
.OurGurusArea .GurusDetail .left-img:before{
  width: 400px !important;
}
.OurGurusArea .GurusDetail .right-img:before{
  width: 435px !important;
  left: 0px;
}
}

@media (max-width: 767px) {
  .guruTeamWrapper p {
    font-size: 24px;
    color: #1e1e1e;
    margin: 0px;
    font-weight: 500;
    padding-bottom: 6.94vw;
  padding-right: 3.5vw;
  }
  .aboutContent.aboutWrapper {
    padding-top: 5.215vw;
    display: flex;
    align-items: center;
    column-gap: 3.911vw;
  }
  .AboutArea .aboutWrapper p{
    font-size: 2.086vw;
    padding-bottom: 0;
  }
  .AboutArea button {
    width: 12.734vw;
    height: 4.042vw;
    font-size: 1.434vw;
    border-radius: 30px;
  }
  .AboutArea button span svg {
    stroke: #231F20;
    width: 1.565vw;
  }
  .ugmkWrapper .UGMKpera p {
    
    padding-top: 20px;
  }
  .UGMKArea .UGMKsliderArea p{
    font-size: 2.086vw;
  }
  .UGMKArea .UGMKsliderArea {
    padding-left: 0px;
  }
  .react-multi-carousel-list{
    padding-left: 7.04vw;
  }
  .UGMKArea .ViewAll.ViewAllMob button{
    width: 12.734vw;
    height: 4.042vw;
    font-size: 1.434vw;
    border-radius: 30px;
  }
  .UGMKArea .ViewAll.ViewAllMob button span svg {
    stroke: #231F20;
    width: 1.565vw;
  }
  .homeBanner .bannerContent .bottomArea button{
    width: 15.734vw;
    height: 5.042vw;
    font-size: 1.734vw;
    border-radius: 30px;
  }
  .howItWorks .UGMKslider button.UGMKsliderBtn{
    width: 15.734vw;
    height: 5.042vw !important;
    font-size: 1.734vw;
    border-radius: 30px;
  }
  .howItWorks .UGMKslider button.UGMKsliderBtn span svg{
    width: 1.965vw;
    stroke: #231F20;
  }
  .howItWorks .UGMKslider button.UGMKsliderBtn:hover {
    background: #8c1211;
    color: #fff;
  }
  .howItWorks .UGMKslider button.UGMKsliderBtn:hover span svg{
    stroke: #ffffff;
  }
  .homeBanner .bannerContent .bottomArea button span svg{
    width: 1.565vw;
  }
  .homeBanner .bannerContent .bottomArea p {
    font-size: 2.086vw;
    line-height: 3.347vw;
}
  .homeBanner .bannerContent .mainHeading h1{
    font-size: 15.778vw;
    line-height: 18.211vw;
  }
  .homeBanner .bannerContent .bottomArea{
    padding-top: 6.427vw;
    padding-left: 2.344vw;
  }
  .howItWorks .leftContent p{
    font-size: 2.086vw;
    padding-top: 16px;
  }
  .howItWorks {
    padding: 4.344vw 0px 2.604vw;
  }
  .howItWorks .textBox h6 {
    font-size: 2.608vw;
    padding: 1.565vw 0vw;
  }
  .howItWorks .IconBox::before{
    display: none;
  }
  .howItWorks .IconBox::after{
    display: none;
  }
  .UGMKArea .UGMKsliderArea .sliderTitle h4 {
    font-size: 5.999vw;
    color: black;
    padding: 1.565vw 0vw;
  }
  .ourCourses .UGMKpera p {
    font-size: 2.086vw !important;
    padding-top: 18px;
  }
  .StudentProgram .CoursesOuter {
    width: 100%;
  }
  .CoursesOuter .coursesBox {
    height: auto;
    width: 100%;
  }
  .CoursesOuter .courseImg img {
    width: 105%;
  }
  .CoursesOuter .courseBottom{
    font-size: 2.875vw;
  }
  .CoursesOuter .countBox{
    font-size: 15vw;
  }
  .UGMKArea.ourCourses .ViewAll.Cviewmob button{
    width: 12.734vw;
    height: 4.042vw !important;
    font-size: 1.434vw;
    border-radius: 30px;
  }
  .UGMKArea.ourCourses .ViewAll.Cviewmob button span svg{
    width: 1.565vw;
    stroke: #231F20;
  }
  .UGMKArea.ourCourses .ViewAll.Cviewmob button:hover span svg{
    stroke: #ffffff;
  }
  .TestimonialArea {
    width: 100%;
   
  }
  .TestimonialArea p{
    width: 100% !important;
    font-size: 2.086vw !important;
  }
  .description h2 {
    font-size: 2.868vw;
  }
  .TestimonialArea .SliderContent .Quote {
    top: -2.74vw;
  } 
  .footerSection .socialMedia ul{
    padding: 0;
  }
  .footerSection .FooterLinks ul{
    padding: 0;
    margin: 0;
  }
  .footerSection .FooterLinks h4{
    font-size: 2.608vw !important;
  }
  .footerSection .FooterLinks a{
    font-size: 2.086vw !important;
  }
  .footerSection .padLeft {
    padding-left: 0;
  }
  .CoursesOuter .coursesBox:after{
width: 100%;
  }
  .footerSection .FooterLinks h4:after{
    width: 18.552vw !important;
  }
  .footerSection .padLeft2{
    padding-left: 0;
  }
  .footerSection .FooterLinks .addressBox{
    font-size: 1.825vw !important;
  }
  .footerSection .FooterLinks .addressBox .icon img {
    width: 1.912vw !important;
  }
  .footerSection .FooterLinks .addressBox{
    column-gap: 1.565vw;
  }
  .footerSection .FooterLinks {
    padding-bottom: 6.521vw !important;
  }
  .footerSection .footerBottom{
    text-align: center;
  }
  .footerSection .footerBottom .innerBox {
    font-size: 2.086vw;
  }
  .innerBox2{
    font-size: 2.086vw;
    line-height: 3.911vw;
  }
  section.ContactBanner {
    margin-top: -15.51vw;
  }
  .gulamMainstudent {
    width: 100%;
    margin-top: -38px;
}
.OurGurusArea .GurusDetail .GurusContentLeft {
  padding-top: 20px;
}
.LifeUGMKArea .UGMKheading h3 {
  top: 0;
}
.LifeUGMKArea .UGMKheading {
  padding-top: 0;
}
.awardArea h3 {
  top: -13px;
}
.OurGurusArea h3 {
  font-size: 54px;
}
.GulamIntroductionBanner .title h3 {
  font-size: 54px;
}
.GulamIntroductionBanner .title h2 {
  bottom: 35px;
}
.GulamIntroductionBanner .title h2 {
  padding-left: 40px;
}
.GulamAboutInner .IntroductionText{
  padding-right: 0;
}
.GulamAboutInner {
  padding-left: 40px;
}
section.ContactBanner {
  margin-top: -19.51vw;
}

input:-internal-autofill-selected {
  appearance: none !important;
  background-image: none !important;
  background-color: transparent !important;
  color: inherit !important;
}


section.GetinTouchContact .locationOuter .locationBox p {
  font-size: 18px;
}
section.GetinTouchContact .locationOuter{
  margin-top: 15px;
}
.custonBtn{
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  margin-top: 20px;
}
.custonBtn span svg{
  width: 16px;
}
.AboutArea h3, .ugmkWrapper h3, .howItWorks h3 {
  font-size: 65px;
}
.UGMKArea.ourCourses h3 {
  font-size: 65px !important;
}
.howItWorks .leftContent .custonBtn{
  display: none;
}

.howItWorks .UGMKslider:before {
  content: '';
  position: absolute;
  left: 9.25vw;
  height: 1.2px;
  background-color: #11111169;
  width: 73vw;
  top: 11.2vw;
    }


.howItWorks .UGMKslider .slick-prev {
  top: 6.4vw;
  width: 8.563vw;
  height: 8.563vw;
}
.howItWorks .UGMKslider .slick-prev:before {
  width: 10.563vw;
  height: 10.563vw;
  position: absolute;
top: 4vw;
}
.howItWorks .UGMKslider .slick-next:before {
  width: 10.563vw;
  height: 10.563vw;
  position: absolute;
  right: 0.3vw;
}
.howItWorks .UGMKslider .slick-next {
  top: 6.2vw;
  width: 8.563vw;
  height: 8.563vw;
}
.howItWorks .UGMKslider {
  margin-top: 40px;
  padding-bottom: 40px;
  text-align: center;
}
.prodigiesHeading .UGMKpera {
  width: 100%;
}
.gulamMainstudent .OurCoursestext .UGMKpera {
  width: 100%;
}
.PrivacyPolicy .pdBottom {
  padding-bottom: 40px;
 
}
.PrivacyPolicy .PrivacyContent .privacyHeading{
  font-size: 32px;
}
.PrivacyPolicy .PrivacyContent .PrivacyRightContent{
  font-size: 16px;
}
.innerbannerContent h2{
  font-size: 60px;
}
.TermuseContent p{
  font-size: 16px;
}
.FAQcontent{
  padding: 20px 0px 60px;
}
.FAQcontent .panel.panel-default{
    padding-bottom: 25px;
    padding-top: 25px;
}
.ViewAll.Cviewmob .custonBtn {
  display: flex;
  gap:1vh;
  margin: 0 auto;
   max-width: 134PX;
}
.OurGurusArea .GurusDetail .GurusImg{
  width: 50%;
}
.OurGurusArea .GurusDetail .right-img:before {
  width: 330px !important;
  left: 0px;
}
.awardArea h3 {
  font-size: 60px;
}
p.top-padd {
  padding-top: 26px;
}
}

@media (max-width: 680px) {
  .UGMKArea .UGMKsliderArea .imgBox img {
    height: 332px;
    width: 268px;
  }
  .courseImg3 img {
    height: 50.43vh !important;
    width: 79vw !important;
}
  .UGMKArea .UGMKsliderArea .UGMKinner::after{
    height: 95%;
  }
  .homeBanner{
    margin-top: -10.5vw;
  }
  .aboutContent.aboutWrapper{
    flex-direction: column;
    width: 100%;
  }
  .aboutContent.aboutWrapper .aboutImgwrapper {
    width: 100%;
  }
  .aboutContent.aboutWrapper{
    width: 100%;
    padding-top: 20px;
  }
  .AboutArea .aboutWrapper p{
    min-width: 100%;
    max-width: 100% !important;
  }
  .ContactTItle h2 {
    font-size: 45px;
    line-height: normal;
}
.WriteToUs .WriteInner h2 {
  font-size: 40px;
}
.WriteToUs .WriteInner {
  padding: 50px 10px !important;
}
}

@media (max-width: 620px) {
  .UGMKArea .UGMKsliderArea .imgBox img {
      height: 315px;
      width: 239px;
  }
  .homeBanner {
    margin-top: -12.5vw;
  }
  .ContactTItle h2{
    top: 35px;
  }
  .ContactTItle h3 {
    font-size: 72px;
    line-height: 80px;
}

.CoursesOuter .rightArrow img {
  width: 6.75vw;
}
.innerbannerContent h2{
  font-size: 60px;
}
}

@media (max-width: 575px) {
  .custom-container {
    max-width: 98.5vw !important;
}
  .homeBanner {
    height: 590px;
    background-position: 84%;
    /* margin-top: -10.5vw; */
  }

 
  .homeBanner .bannerContent{
    top: 22% ;
  }
   
  .AboutArea .aboutWrapper p {
    font-size: 2.434vw;
    min-width: 100%;
  }
  .aboutContent.aboutWrapper{
    flex-direction: column;
  }
  .AboutArea button {
    width: 17.734vw;
    height: 6.042vw;
    font-size: 1.934vw;
  }
  .AboutArea button span svg {
    width: 2.565vw;
  }
  .spiritualContent p {
    font-size: 3.113vw !important;
    line-height: 3.854vw;
  }
  .spiritualContent h2 {
    font-size: 5.208vw !important;
    line-height: 8.104vw;
  }
  .UGMKArea .ViewAll.ViewAllMob button{
    width: 17.734vw;
    height: 6.042vw;
    font-size: 1.934vw;
  }
  .UGMKArea .ViewAll.ViewAllMob button span svg {
    width: 2.565vw;
  }
 
  .howItWorks .IconBox{
    
    width: 22.082vw;
    height: 22.082vw;
  }
  .howItWorks .IconBox img {
    height: 9.128vw;
    width: 12vw;
  }
  .howItWorks .UGMKslider button.UGMKsliderBtn{
    width: 17.734vw;
    height: 6.042vw !important;
    font-size: 1.934vw;
  }
  .howItWorks .UGMKslider button.UGMKsliderBtn span svg{
    width: 2.565vw;
  }
  .homeBanner .bannerContent .bottomArea{
    width: 100%;
    padding-left: 0;
  }
  .homeBanner .bannerContent .bottomArea p {
    font-size: 2.434vw;
    line-height: 3.347vw;
  }
  .homeBanner .bannerContent .mainHeading h1 {
    font-size: 14.778vw;
    line-height: 15.211vw;
}
.UGMKArea .UGMKsliderArea .imgBox img {
  height: 314px;
  width: 221px;
}
.gulamMainprodigies {
  width: 100%;
  margin-top: -26px;
}
.gulamMainstudent {
  width: 100%;
  margin-top: -35px;
}
.CoursesOuter .rightArrow img {
  width: 9.75vw;
}
.StudentProgram .CoursesOuter{
  padding-bottom: 0;
}
.TestimonialArea .SliderContent .Quote {
  top: -5.74vw !important;
}
.TestimonialArea .SliderContent .Quote img {
  width: 6.703vw !important;
}
.TestimonialArea .SliderContent .QuoteRight img {
  width: 6.703vw !important;
}
.TestimonialArea .SliderContent .QuoteRight {
  bottom: -8.448vw !important;
}
.GulamAboutInner {
  padding: 60px 0px;
}
.GulamAboutInner .IntroductionText p, .OurGurusArea .GurusContentLeft p, .guruTeamWrapper p{
  font-size: 20px;
  line-height: 34px;
}
.GulamIntroductionBanner .title h3{
  line-height: normal;
}
.GulamIntroductionBanner .title h2 {
  bottom: -8px;
  font-size: 40px;
}
.GulamAboutInner .IntroductionText {
  margin-top: 40px;
}
.spritualArea p {
  font-size: 25px !important;
}

.spritualArea h2{
  font-size: 40px !important;
}
.guruTeamWrapper h2 {
  font-size: 40px;
}
.heading {
  padding-bottom: 0;
}

.LifeUGMKArea .UGMKheading h2 {
  font-size: 40px;
}
.FAQcontent .panel-title a {
  font-size: 3.463vw;
}
.FAQcontent .panel-body {
  font-size: 2.646vw;
}
.ugmkWrapper .UGMKpera p {
  font-size: 4.434vw !important;
}
.howItWorks .leftContent p {
  font-size: 2.434vw;
}
.howItWorks .textBox h6 {
  font-size: 20px;
  padding: 14px 0px;
}
.spritualArea h2 {
  font-size: 50px !important;
}
.spritualArea p {
  font-size: 20px !important;
  line-height: 1.2;
padding-bottom: 10vh;
}
.UGMKArea.ourCourses h3 {
  font-size: 50px !important;
}
.ourCourses .UGMKpera p{
  font-size: 2.434vw !important;
}
.UGMKArea .ViewAll {
  text-align: center;
}
.testimonialHeading h3{
  font-size: 60px;
}
.testimonialHeading h3 {
  font-size: 58px !important;
  top: -4vw !important;
}
.testimonialSlider {
  margin-top: 40px;
}
.false.header {
  padding-top: 20px;
}
.OurGurusArea .GurusContentLeft h4{
  font-size: 26px;
}
}

@media (max-width: 480px) {
  .homeBanner .bannerContent {
    top: 28% !important;
  }
  .homeBanner .bannerContent .bottomArea {
    padding-top: 6.427vw;
  }
  .homeBanner .bannerContent .bottomArea button {
    width: 22.734vw;
    height: 7.042vw;
    font-size: 2.434vw;
    border-radius: 30px;
  }
  .homeBanner .bannerContent .bottomArea button span svg {
    width: 2.565vw;
  }

  .howItWorks .leftContent p {
    font-size: 2.734vw;
  }
  .ourCourses .UGMKpera p{
    font-size: 2.434vw !important;
  }
  .TestimonialArea p{
    font-size: 2.734vw !important;
  }
  .TestimonialArea .SliderContent .QuoteRight {
    bottom: -7.448vw;
  }
  .TestimonialArea .SliderContent .Quote {
    top: -4.74vw;
  }
  .center {
    transform: scale(1.6) !important;
    margin: 1.2rem !important;
  }
  .UGMKArea.ourCourses .ViewAll.Cviewmob button{
    width: 20.734vw;
    height: 6.042vw !important;
    font-size: 2.434vw;
    border-radius: 30px;
  }
  .UGMKArea.ourCourses .ViewAll.Cviewmob button span svg{
    width: 2.565vw;
  }
      .description h2 {
        font-size: 3.875vw !important;
    }
  .footerSection .socialMedia li a {
    width: 7.916vw !important;
    height: 7.916vw !important;
  }
  .footerSection .socialMedia li img {
    width: 3.371vw;
    height: 3.373vw;
  }
  .footerSection .FooterLinks a {
    font-size: 3.086vw !important;
  }
  .footerSection .FooterLinks h4 {
    font-size: 3.608vw !important;
  }
  .footerSection .FooterLinks h4:after {
    width: 50.552vw !important;
  }
  .footerSection .FooterLinks .addressBox {
    font-size: 2.825vw !important;
  }
  .FooterLinks {
    width: 100%;
  }
  .socialMedia {
    width: 100%;
}
.homeBanner {
  background-position: 82%;
}
.aboutImgwrapper {
  padding-bottom: 20px;
}
.homeBanner {
  margin-top: -14.5vw;
}
.header .navbar {
  padding: 3vw 0px !important;
}
.AboutArea .aboutWrapper p {
  font-size: 2.734vw;
}
.AboutArea button {
  width: 22.734vw;
  height: 7.042vw;
  font-size: 2.434vw;
  border-radius: 30px;
}
.AboutArea {
  padding: 6.313vw 0vw 11.771vw 0vw;
}
.spiritualContent h2 {
  font-size: 9.208vw !important;
  line-height: 8.104vw;
}
.spiritualContent p {
  font-size: 6.113vw !important;
  line-height: 6.854vw;
  padding-top: 4.833vw;
  padding-left: 2vw;
  padding-right: 2vw;
}
.UGMKArea .UGMKsliderArea p {
  font-size: 3.786vw;
}
.UGMKArea .UGMKsliderArea .sliderTitle h4 {
  padding-top: 1vh;
  font-size: 5.999vw;
}
.howItWorks .textBox h6 {
  font-size: 4.608vw;
}
.howItWorks {
  /* padding: 30.44vw 0px 9.604vw; */
}
.socialMedia .FooterLogo img {
        width: 50% !important;
        padding: 12px 0px;
    }
    .howItWorks h3 {
    top: 20.781vw;
}

.howItWorks .UGMKslider{
  position: relative;
}
  
    .UGMKArea .ViewAll.ViewAllMob button {
        width: 22.734vw;
        height: 7.042vw;
        font-size: 2.434vw;
        border-radius: 30px;
    }
    .howItWorks .UGMKslider button.UGMKsliderBtn{
      width: 22.734vw;
        height: 7.042vw !important;
        font-size: 2.434vw;
        border-radius: 30px;
    }
    section.ContactBanner {
      margin-top: -22.51vw;
  }

  .GulamIntroductionBanner .title h2 {
    bottom: -32px;
}
.prodigiesHeading .UGMKpera {
  width: 100%;
}
.FAQcontent {
  padding: 30px 0px 60px;
}
.FAQcontent .panel-title a {
  font-size: 3.663vw;
}
.AboutArea h3, .ugmkWrapper h3, .howItWorks h3 {
  font-size: 54px;
}
.awardArea h3 {
  font-size: 50px;
}
.OurGurusArea .GurusDetail .right-img {
  width: 100% !important;
}
.OurGurusArea .GurusDetail .right-img:before{
  width: 100% !important;
}
.OurGurusArea .GurusDetail .GurusImg{
  width: 100%;
}
.OurGurusArea .GurusDetail .left-img:before{
  width: 100% !important;
  left: 0px;
}
}

@media (max-width: 420px) {
    .homeBanner {
        margin-top: -16.5vw;
    }
    .courseImg3 img {
      height: 50vh !important;
      width: 79vw !important;
  }
    .homeBanner .bannerContent .mainHeading h1 {
        font-size: 17.778vw;
        line-height: 24.211vw;
    }
    .homeBanner .bannerContent .mainHeading h1.spaceLeft{
      line-height: 15.969vw;
    }
      .homeBanner .bannerContent .mainHeading h1 {
        font-size: 20.778vw;
        line-height: 18.211vw;
    }
    .homeBanner {
      margin-top: 0;
      background: url('../Assets/img/mobile.png') no-repeat !important;
      background-size: cover !important;
      height: 813px;
    }
    .homeBanner .bannerContent {
      top: 30% !important;
    }
    .homeBanner .bannerContent .bottomArea p {
      font-size: 4vw;
      line-height: 5.847vw;
      text-align: center;
      padding: 0px 0px;
    }
    .homeBanner .bannerContent .bottomArea{
      text-align: center;
    }
    .homeBanner .bannerContent .bottomArea button {
      width: 25.734vw;
      height: 9.042vw;
      font-size: 2.834vw;
      border-radius: 30px;
    }
    .AboutArea {
      padding: 13.313vw 0vw 15.771vw 0vw;
    }
    .AboutArea h3 {
      font-size: 9.388vw;
      top: 16.646vw;
    }
    .gulamMainstudent .OurCoursestext .UGMKpera {
      width: 83%;
    }
    .ourCourses .UGMKpera p {
      font-size: 4.2vw !important;
      width: 90vw !important;
    }
    .UGMKArea.ourCourses h2 {
      line-height: 8.208vw;
      font-size: 8.25vw !important;
    }
    .GulamAboutInner .IntroductionText p, .OurGurusArea .GurusContentLeft p, .guruTeamWrapper p {
      font-size: 18px;
      line-height: 30px;
  }
  .guruTeamWrapper {
    margin-top: -11%;
}
.GulamIntroductionBanner .title h3 {
  font-size: 40px;
}
.TestimonialArea .SliderContent .QuoteRight {
  bottom: -4.448vw !important;
}
section.ContactBanner {
  margin-top: -27.51vw;
}
.FAQcontent .panel-title a {
  font-size: 4.663vw;
}
.FAQcontent .panel-body {
  padding-top: 10px;
  font-size: 4.146vw;
}

.TermuseContent{
  padding: 40px 0px 25px;
}
.FAQcontent .panel.panel-default {
  border-bottom: 1px solid #878787;
  padding-bottom: 22px;
  padding-top: 22px;
}
.innerbannerContent{
  text-align: center;
}
section.GetinTouchContact .locationOuter .locationBox h4{
  font-size: 24px;
}
section.GetinTouchContact .locationOuter .locationBox .IconBox img {
  width: 25%;
}
.TestimonialArea {
  padding-top: 10vh !important;
}
.mob-banner{
  background-image: url('../Assets/img/mob-terms.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: TOP center;
  height: 380px;
}
.mob-banner img{
  display: none;
}
.mob-privay img{
  display: none;
}
.mob-privay{
  background-image: url('../Assets/img/mob-privacy.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: TOP center;
  height: 380px;
}

.mob-about img{
  display: none;
}
.mob-about{
  background-image: url('../Assets/img/mob-about.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: TOP center;
  height: 380px;
}

.mob-refund img{
  display: none;
}
.mob-refund{
  background-image: url('../Assets/img/mob-refund.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: TOP center;
  height: 380px;
}


.mob-faq img{
  display: none;
}
.mob-faq{
  background-image: url('../Assets/img/mob-faq.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: TOP center;
  height: 380px;
}
.innerbannerContent{
  top: 60px;
}
.innerbannerContent h2 {
  font-size: 60px;
  padding-top: 40px;
}

.OurGurusArea .GurusContentLeft h4 {
  font-size: 16px;
}
.GulamAboutInner .IntroductionText p, .OurGurusArea .GurusContentLeft p, .guruTeamWrapper p {
  font-size: 16px;
  line-height: 27px;
  padding-top: 1.5vh;
}
.GulamIntroductionBanner .title h2 {
  bottom: -18px;
}
.GulamIntroductionBanner .title h2 {
  padding-left: 14px;
}
}
@media (max-width: 390px){
  .howItWorks .UGMKslider:before {
    content: '';
    position: absolute;
    left: 9.5vw;
    height: 1.2px;
    background-color: #11111169;
    width: 72vw;
    top: 11.2vw;
}
}


@media (max-width: 360px) {
  .homeBanner {
    margin-top: -22.5vw;
    position: relative;
    z-index: 1;
  }
  .courseImg3 img {
    height: 40.43vh !important;
    width: 79vw !important;
}
  .homeBanner:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 813px;
    top: 0;
    left: 0;
    background-color: #11111124;
    z-index: -1;
  }
  .homeBanner .bannerContent {
    top: 40% !important;
  }
  .homeBanner .bannerContent .mainHeading h1 {
    font-size: 20.778vw;
    line-height: 16.211vw;
  }
  .homeBanner .bannerContent .bottomArea p {
    font-size: 4.267vw;
    line-height: 6.933vw;
    padding: 0px 10px;
  }

  .homeBanner .bannerContent{
    left: 0;
    padding: 0px 3.781vw;
  }
  .homeBanner .mobEnrollbtn {
    height: 11.083vw;
    line-height: 11.083vw;
    position: fixed; 
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 9999 !important; 
  }
  .homeBanner .bannerContent .bottomArea button {
    width: 32.734vw;
    height: 11.042vw;
    font-size: 3.434vw;
    margin-top: 6.125vw;
  }
  .homeBanner .bannerContent .bottomArea button span svg {
    width: 4.565vw;
  }
  .homeBanner .bannerContent .bottomArea{
    text-align: center;
  }
 
  .AboutArea h3 {
    font-size: 12.388vw;
  }
  .AboutArea .aboutContent h2 {
    font-size: 11.25vw !important;
  }
  .AboutArea .aboutWrapper p {
    font-size: 4.434vw;
  }
  .AboutArea button{
    width: 33.734vw;
    height: 11.042vw;
    font-size: 3.434vw;
  }
  .AboutArea button span svg{
    width: 4.565vw;
  }
  .spiritualContent h2 {
    font-size: 10.208vw !important;
    line-height: 10.104vw;
  }
  .spiritualContent p {
    font-size: 6.113vw !important;
    line-height: 7.854vw;
    padding-top: 4.833vw;
}
.prodigiesHeading .UGMKpera {
  width: 100%;
}

.ugmkWrapper h3 {
  font-size: 15.375vw;
  line-height: 11.813vw;
}
  .ugmkWrapper .UGMKpera p {
    font-size: 4.434vw;
}

    .UGMKArea .UGMKsliderArea .imgBox img {
      height: 58.135vw;
      width: auto;
  }

      .UGMKArea .UGMKsliderArea .sliderTitle h4 {
        font-size: 4.999vw;
        color: #1e1e1e;
        padding: 4.565vw 0vw;
    }
        .UGMKArea .UGMKsliderArea p {
        font-size: 5.086vw;
    }
    .UGMKArea .ViewAll.ViewAllMob button{
      width: 29.734vw;
    height: 10.042vw;
    font-size: 3.434vw;
    }
    .UGMKArea .ViewAll.ViewAllMob button span svg {
        width: 4.565vw;
    }


    .howItWorks .leftContent p {
      font-size: 4.434vw;
  }
  .howItWorks .UGMKslider .slick-prev {
    top: 6.4vw;
    width: 8.563vw;
    height: 8.563vw;
}
.howItWorks .UGMKslider .slick-prev:before {
    width: 9.563vw;
    height: 9.563vw;
    position: absolute;
    left: 1vw;
    top: 6.2vw;
}
.howItWorks .UGMKslider .slick-next:before {
    width: 9.563vw;
    height: 9.563vw;
    position: absolute;
    top: 6.5vw;
    right: 1vw;
}
.howItWorks .UGMKslider .slick-next {
    top: 6.2vw;
    width: 8.563vw;
    height: 8.563vw;
}
    .howItWorks .IconBox {
      width: 22.082vw;
      height: 22.082vw;
      position: relative;
    }
    .howItWorks .UGMKslider{
      position: relative;
    }

        .howItWorks .IconBox img {
          height: 22vw;
          width: 22vw;
    }
        
        .howItWorks .textBox h6 {
        font-size: 5.608vw;
        padding: 3.565vw 0vw;
    }
    .howItWorks .UGMKslider button.UGMKsliderBtn {
      width: 31.734vw;
      height: 10.042vw !important;
      font-size: 3.434vw;
  }
        .howItWorks .UGMKslider button.UGMKsliderBtn span svg {
        width: 4.565vw;
    }
    .UGMKArea.ourCourses h3 {
    font-size: 10.375vw !important;
  }
  .UGMKArea.ourCourses h2 {
    line-height: 10.208vw;
    font-size: 11.25vw !important;
  }
  .ourCourses .UGMKpera p {
        font-size: 3.434vw !important;
    }
        .CoursesOuter .courseBottom {
        font-size: 4.875vw;
        padding: 3.2vw 0vw;
    }
    .CoursesOuter .rightArrow img {
        width: 10.75vw;
    }
    .TestimonialArea p {
      font-size: 4.434vw !important;
  }
  .testimonialHeading h3 {
    font-size: 12.375vw !important;
  }
  .testimonialHeading h2 {
    padding-bottom: 4.042vw !important;
    font-size: 10.25vw !important;
  }
  .testi_img {
    max-width: 12.813vw !important;
    max-height: 12.813vw !important;
  }
  .description h2 {
    font-size: 5.875vw !important;
  }
  .TestimonialArea .SliderContent .Quote img {
    width: 7.703vw;
  }
  .TestimonialArea .SliderContent .QuoteRight img {
    width: 7.703vw;
  }
  .footerSection .socialMedia .FooterLogo img {
    height: 15.719vw;
    width: 46.917vw;
  }
  .UGMKArea .UGMKsliderArea button{
    top: 30%;
  }
  .ugmkWrapper h2 {
    font-size: 11.25vw !important;
}
.howItWorks .UGMKslider:before {
  top: 13vw;
  width: 69.6vw;
}
.gulamMainstudent .OurCoursestext .UGMKpera {
  width: 100%;
}
.ourCourses .UGMKpera p {
  font-size: 4.434vw !important;
}
@media screen and (max-width: 767px) {
  .UGMKpera p {
    font-size: 2.25vw !important;
    color: #1e1e1e;
    margin: 0px;
    font-weight: 600;
  }
  .howItWorks .UGMKslider:before {
    top: 13vw;
    width:72vw;
  }
}
.StudentProgram{
  margin-top: 12.208vw;
  padding-bottom: 40px;
}
.courseImg3 img {
  width: 100%;
}
.TestimonialArea .SliderContent {
  padding-bottom: 42px;
}
.TestimonialArea .SliderContent .Quote img {
  width: 10.703vw !important;
}
.TestimonialArea .SliderContent .Quote {
  top: -8.74vw !important;
}
.TestimonialArea .SliderContent .Quote {
  top: -8.74vw !important;
}
.testimonialHeading{
  padding: 5.208vw 0px 6.823vw;
}
.TestimonialArea {
  padding: 0vw 0vw 10.813vw;
}
.carousel-container{
  padding: 9.042vw 0vh;
}
.UGMKArea.ourCourses .ViewAll.Cviewmob button {
  width: 29.734vw;
  height: 10.042vw !important;
  font-size: 3.434vw;
}
.UGMKArea.ourCourses .ViewAll.Cviewmob button span svg {
  width: 3.565vw;
}
section.ContactBanner {
  margin-top: -32.51vw;
}
.CoursesOuter .countBox {
  font-size: 50px;
  left: 12px;
  top: 12px;
}
.TestimonialArea p {
  font-size: 15px !important;
}
.TestimonialArea .SliderContent .QuoteRight {
  bottom: 0.552vw !important;
}
.TestimonialArea .SliderContent .QuoteRight img {
  width: 8.703vw !important;
}
.howItWorks .leftContent h2{
  line-height: 17.771vw;
  font-size: 11.25vw !important;
}
.footerSection .panel-default{
  padding-top: 14px;
}
.footerSection .FooterLinks h4:after{
  bottom: -8px;
}

.footerSection .FooterLinks .addressBox {
  font-size: 3.825vw !important;
  padding-top: 12px;
}
.footerSection .footerBottom {
  text-align: left;
}
.footerSection .FooterLinks .addressBox {
  font-size: 14px !important;
}
.footerSection .footerBottom .innerBox{
  font-size: 14px !important;
  line-height: 18px;
}
.innerBox2{
  font-size: 14px !important;
}
.breadcrumb-wrapper {
  position: absolute;
  margin-top: -85px !important;
  top: 15vh;
  left: 5vw;
}

section.ContactBanner .bannerContent h2{
  padding-top: 40px;
  font-size: 50px;
  text-align: center;
}
.ContactTItle h2 {
  font-size: 37px;
  line-height: normal;
}
.ContactTItle h3 {
  font-size: 55px;
  line-height: 80px;
}
.FAQcontent .panel-title a {
  font-size: 20px;
  padding-right: 47px;
}
.navbar .Logo img{
  height: auto;
}
.ugmkWrapper {
  padding: 55px 0px;
}
.FAQcontent .panel-title > a:before{
    right: 12px;
    position: absolute;
    font-weight: 400;
}
.OurGurusArea .GurusDetail .GurusImg img {
  width: 75%;
  margin: 0 auto;
}
.OurGurusArea .GurusDetail .GurusImg{
  text-align: center;
}
.OurGurusArea .GurusDetail .GurusImg:before{
  left: 0px;
}
.OurGurusArea .GurusDetail .right-img img {
  width: 100%;
}
.StudentProgram .CoursesOuter{
  padding-top: 30px;
}
.CoursesOuter.topPad {
  margin-top: 0;
}

}

@media (max-width: 360px) {
  section.GetinTouchContact .locationOuter .locationBox p {
    font-size: 14px;
  }
  
}

