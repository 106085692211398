.footerSection {
  background: url(../Assets/img/footerBg.png) no-repeat;
  background-size: cover;
  padding: 3.125vw 0px 0px;
object-fit: cover;
  .socialMedia {


    ul {
      margin-top: 2.604vw !important;
      padding-left: 0px;
    }

    li {
      list-style: none;

      a {
        display: inline-flex;
        border: 1.5px solid #8c1211;
        border-radius: 50%;
        margin-right: 0.521vw;
        padding: 0.26vw !important;
        width: 2.916vw !important;
        height: 2.916vw !important;
        justify-content: center;
        align-items: center;
      }

      img {
        width: 1.371vw;
        height: 1.373vw;
      }
    }
  }

  .padLeft {
    padding-left: 1.563vw;
  }

  .padLeft2 {
    padding-left: 3.125vw;
  }

  .panel-default {
    display: none;
  }

  .FooterLinks {
    ul {
      padding: 0px;
    }

    h4 {
      font-weight: 600;
      font-size: 1.25vw;
      padding-bottom: 0.521vw;
      position: relative;
      margin-bottom: 1.042vw !important;
    }

    h4:after {
      position: absolute;
      display: block;
      content: "";
      width: 10.552vw;
      height:  1.5px;
      background:  #8c1211;
   
      left: 0;
      bottom: -8px;
    }

    li {
      list-style: none;
      padding-bottom: 0.781vw;
    }

    a {
      border: none;
      font-size: 1.042vw;
      color: #1e1e1e;
      font-weight: 400;
      text-decoration: none;
      transition: all 0.3s;
    }

    a:hover {
      color: #8c1211;
    }

    .addressBox {
      display: flex;
      justify-content: flex-start;
      align-items: start;
      font-size: 1.042vw;
      color: #1e1e1e;
      padding-bottom: 0.781vw;

      .icon {
        margin-right: 0.417vw;

        img {
          width: 0.912vw;
        }
      }
    }

    @media screen and (max-width: 767px) {
      padding-bottom: 0.521vw;
    }
  }

  .footerBottom {
    margin-top: 2.604vw;
    border-top: 0.052vw solid #8577623b;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.563vw 0px;
    font-size: 0.833vw;

    @media screen and (max-width: 767px) {
      .footerSection {
        background: url(../Assets/img/mobileFooterImg.png) no-repeat;
        background-size: cover;
        padding: 3.125vw 0px 0px;
      object-fit: cover;}
      .footerSection .FooterLinks h4 {
        font-size: 2vw !important;
        margin-bottom: 5.042vw ;
      }
    
      .footerSection .FooterLinks h4:after {
        width: 50vw;
      }
    }

    @media screen and (max-width: 767px) {
      display: block;
      line-height: 1.042vw;
      margin-top: 0.26vw;
      .footerSection {
        background: url(../Assets/img/mobileFooterImg.png) no-repeat;
        background-size: cover;
        padding: 3.125vw 0px 0px;
      object-fit: cover;}

      .innerBox {
        padding-bottom: 0.521vw;
      }
    }
  }
  .socialMedia{
    h4 {
      font-weight: 600;
      font-size: 1.25vw;
      padding-bottom: 0.521vw;
      position: relative;
      margin-bottom: 1.042vw !important;
    }

    h4:after {
      position: absolute;
      display: block;
      content: "";
      width: 10.552vw;
      height:  1.1px;
      background:  #8c1211;
   
      left: 0;
      bottom: 0;
    }
  }
}

@media (max-width: 991px) {
  .footerSection {
    background: url(../Assets/img/mobileFooterImg.png) no-repeat;
    background-size: cover;
    padding: 3.125vw 0px 0px;
  object-fit: contain;}
  .footerSection .socialMedia li a {
    width: 3.916vw !important;
    height: 3.916vw !important;
  }

  .footerSection .socialMedia li img {
    width: 1.671vw;
    height: 1.673vw;
  }
}

@media (max-width: 480px) {
  .footerSection {
    background: url(../Assets/img/mobileFooterImg.png) no-repeat;
    background-size: cover;
    padding: 3.125vw 0px 0px;
  object-fit: contain;}
  .footerSection .socialMedia li a {
    width: 7.916vw !important;
    height: 7.916vw !important;
  }

  .footerSection .socialMedia .FooterLogo img {
    height: auto;
    width: 46.917vw;
  }

  .footerSection .socialMedia li img {
    width: 3.371vw;
    height: 3.373vw;
  }

  .footerSection .FooterLinks a {
    font-size: 3.086vw !important;
  }

  .footerSection .FooterLinks h4 {
    font-size: 5.5vw !important;
    margin-bottom: 5.042vw ;
  }

  .footerSection .FooterLinks h4:after {
    width: 50vw;
  }

  .footerSection .FooterLinks .addressBox {
    font-size: 2.825vw !important;
  }

  .footerSection .FooterLinks .addressBox .icon img {
    width: 4.912vw !important;
  }

  .UGMKArea .UGMKsliderArea .imgBox img {
    height: 232px;
    width: 178px;
  }

  .innerBox2 {
    font-size: 3.5vw;
    line-height: 8.911vw;
  }

  .footerSection .footerBottom .innerBox {
    font-size: 3.5vw;
    padding-top: 3.2vw;
  }
}
@media (max-width: 375px) {
  .footerSection {
    background: url(../Assets/img/mobileFooterImg.png) no-repeat;
    background-size: cover;
    padding: 3.125vw 0px 0px;
  object-fit: contain;}
  .footerSection {
 
    .panel-default {
      display: block;
    }
  }
  .gulamFooterwrapper h2.panel-title {
    width: 45%;
  }
  .footerSection .FooterLinks .addressBox {
    font-size: 14px !important;
  }
  .footerSection .FooterLinks a{
    font-size: 16px !important;
  }
  .footerSection .FooterLinks h4{
    font-size: 20px !important;
  }
  .footerSection .FooterLinks h4:after{
    bottom: -4px;
  }
}

.gulamFooterwrapper .panel-title > a:before {
  float: right !important;
  font-family: FontAwesome;
  content: "\f067";
  padding-right: 5px;
  color: #8c1211;
  font-weight: normal;
}
.conumber{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.secondCoNumber{
  padding-left: 3px;
}