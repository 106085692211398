section.ContactBanner {
  margin-top: -120px;
  position: relative;

  .BannerHeading {
    position: absolute;
    z-index: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: "EB Garamond", serif;
    font-size: 100px;
    font-weight: 900;
  }

  .aboutmenu {
    position: absolute;
    top: 100px;

    ul {
      position: absolute;
      top: 0;
      left: 0;
      width: 300px;

      li {
        list-style: none;
        display: inline-block;
        color: #faf3eb;
        font-size: 14px;
      }
      a {
        padding: 0px 4px;
        color: #faf3eb;
      }
    }
    ul li:nth-child(1) a {
      color: #faf3eb7f;
    }
  }
}

.GetinTouch {
  background: #faf6f0;
  padding-top: 120px;
  position: relative;

  h2 {
    text-align: center;
  }
  h3 {
    color: #d5b376;
    opacity: 0.1;
    font-size: 152px;
    font-family: "Karla", sans-serif;
    font-weight: 800;
    position: absolute;
    top: 45px;
    left: 23%;
    right: 0;
  }
}

.GetinTouch {
  .locationOuter {
    padding: 50px 0px 50px;
  }

  .borderR {
    border-right: 1px solid #1e1e1e1f;
  }
  .borderL {
    border-left: 1px solid #1e1e1e1f;
  }
  .locationBox {
    text-align: center;

    .IconBox {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.0901960784);
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f6efe2;
      margin: 0px auto 20px;

      img {
        width: 20px;
      }
    }

    h4 {
      font-size: 1.25vw;
      color: #1e1e1e;
      font-weight: 500;
      padding-bottom: 12px;
    }
    p {
      color: #7e7e7e;
      font-size: 16px;
      line-height: normal;
      font-weight: 500;
      margin: 0;
      padding-bottom: 12px;

      a {
        color: #8c1211;
        font-size: 16px;
        text-decoration: underline;
      }
    }
  }
}

.WriteToUs {
  padding: 50px 0px;
  background: #faf6f0;

  .WriteInner {
    padding: 40px 30px;
    background: #f6efe2;

    h2 {
      color: #8c1211;
      font-size: 40px;
      font-family: "EB Garamond", serif;
      font-weight: 700;
      padding-bottom: 25px;
      text-align: center;
    }
    button {
      width: 120px;
      height: 45px;
      text-transform: uppercase;
      font-size: 14px;
      border-radius: 30px;
      background: #fff;
      transition: all 0.3s;
      border: 1px solid #2b2a29;
      box-shadow: 0px 6px 9px #00000017;
      margin: 10px auto 0px;
      display: block;

      span {
        vertical-align: middle;
        display: inline-block;
        margin-left: 5px;
        margin-top: -4px;
      }

      img {
        width: 22px;
      }
    }

    input,
    select {
      border: none;
      background: transparent;
      border-bottom: 1px solid #1e1e1e82;
      width: 100%;
      height: 45px;
      padding: 6px 0px;
    }
    textarea {
      border: none;
      background: transparent;
      border-bottom: 1px solid #1e1e1e82;
      width: 100%;
      padding: 6px 0px;
      margin-bottom: 30px;
    }
    input:focus {
      outline: none;
    }

    .inputBox {
      margin-bottom: 30px;
    }
  }
}
small.error {
  font-size: 12px;
  color: red !important;
  margin-top: -2px !important;
  display: block;
}

@media screen and (max-width: 767px) {
  .GetinTouch {
    padding-top: 87px;

    h3 {
      left: 5px;
      font-size: 70px;
    }

    .locationBox {
      padding-bottom: 30px;
    }

    .locationOuter {
      padding: 0px 0px 0px;
    }
  }
  .WriteToUs {
    padding: 20px 0px 50px;
  }
 
}
@media (max-width: 991px) {
  .false.header {
    padding-top: 25px;
  }
  .false.header .gulamHeaderwrapper {
    padding: 0px !important;
  }
}




.WriteToUs .inputBox input,
.WriteToUs .inputBox textarea,
.WriteToUs .inputBox select {
  transition: border 0.3s ease; /* Add transition */
}
.inputBox select {
  cursor: pointer; /* Change the cursor to a pointer */
}
.inputBox select option {
  cursor: pointer; /* Change the cursor to a pointer for the dropdown options */
}

