.homeBanner {
  margin-top: 0vw;
  background: url(../../Assets/img/homeBanner.png) no-repeat;
  width: 100%;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 1075px;
  position: relative;

  .mobEnrollbtn {
    display: none;
    z-index: 999 !important;
  }
 
  .bannerContent {
    width: 70%;
    text-align: left;
    z-index: 0;
    // top: 16%;
    top: 12.031vw !important;
    position: absolute;

    .mainHeading {
      h1 {
        font-family: "EB Garamond", serif;
        font-size: 10.625vw;
        font-weight: 900;
        color: #fdf6ee;
      }

      h1.spaceLeft {
        padding-left: 5.208vw;
        line-height: 7.969vw;
        color: #fdf6ee;
        position: relative;
      }
    }

    .bottomArea {
      width: 56%;
      // padding-top: 4.427vw;

      p {
        font-size: 1.042vw;
        color: #f6f7f9;
        line-height: 1.563vw;
        font-weight: 400;
        margin: 0;
      }

      button {
        // width: 174px;
        // height: 3.125vw;
        // text-transform: uppercase;
        // border: none;
        // font-size: 16px;
        // border-radius: 1.563vw;
        // margin-top: 1.302vw;
        // transition: all 0.3s;
        // background: #fff;
        width: 174px;
        height: 60px;
        text-transform: uppercase;
        border: none;
        font-size: 0.833vw;
        border-radius: 30px;
        margin-top: 3.125vw;
        transition: all 0.3s;
        background: #fff;
    

        span {
          vertical-align: middle;
          display: inline-block;
          margin-left: 0.521vw;
          margin-top: -0.208vw;
        }

        img {
          width: 0.847vw;
        }
      }

      button:hover {
        background: #8c1211;
        color: #fff;
         
      }
    }
  }
}

//------------------------ABOUT SECTION START-----------------------

.AboutArea {
  padding: 10% 0px 7.813vw 0px;
  position: relative;
  background: #faf6f0;
  overflow: hidden;

  h3 {
    color: #d5b376;
    opacity: 0.1;
    font-size: 140px;
    line-height: 7.813vw;
    font-family: "Karla", sans-serif;
    font-weight: 800;
    position: absolute;
    top: 8.646vw;
    // left: 21px;
    left: 0.208vw !important;
  }

  .aboutContent {
   

    h2 {
      padding-bottom: 1.042vw !important;
      
    }
  }

  .AboutImg {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 8vh;
    img {
      width: 54.271vw;
    }
  }

  p {
    width: 38.438vw !important;
    height: 17.5vw;
    font-size: 1.25vw;
    color: #1e1e1e;
    font-weight: 500;

    // &:nth-child(3) {
    //   overflow: hidden;
    //   display: -webkit-box;
    //   -webkit-line-clamp: 3;
    //   line-clamp: 2;
    //   -webkit-box-orient: vertical;
    // }
  }

  button {
    width: 174px;
    height: 60px;
    text-transform: uppercase;
    font-size: 16px;
    border-radius: 30px;
    margin-top: 2.083vw;
    transition: all 0.3s;
    border: 0.052vw solid #2b2a29;
    box-shadow: 0px 0.313vw 0.469vw #00000017;
    background: #fff;
    transition: all 0.3s;

    span {
      vertical-align: middle;
      display: inline-block;
      margin-left: 0.521vw;
      margin-top: -0.208vw;
    }

    img {
      width: 0.847vw;
    }
  }
  button:hover {
    background: #8c1211;
    color: #fff;
  }
}

//*******************MUSIC IS SPRITUAL START******************

.spritualArea {
  background: transparent url(../../Assets/img/footerBg.png) 0% 0% no-repeat;
  background-size: cover;
  padding: 2.604vw 0px;
  text-align: center;

  h2 {
    font-size: 5.208vw !important;
  }

  p {
    color: #1e1e1e;
    font-weight: 500;
    font-size: 2.813vw !important;
    width: 50%;
    margin: 0px auto;
  }
}

//*************************UGMKAREA**************************

.UGMKArea {
  padding: 9.813vw 0px 7.167vw;
  background: #faf6f0;
  position: relative;
  overflow: hidden;
  padding-top: 15vh;
  .ViewAll.ViewAllMob {
    display: none;
  }

  h3 {
    color: #d5b376;
    opacity: 0.1;
    font-size: 9.375vw;
    font-family: "Karla", sans-serif;
    font-weight: 800;
  }

  .UGMKpera p {
    font-size: 1.25vw !important;
    color: #1e1e1e;
    margin: 0px;
    font-weight: 500;
  }

  .ViewAll {
    text-align: right;
  }

  button {
    // width: 7.656vw;
    // height: 3.125vw;
    // text-transform: uppercase;
    // font-size: 16px;
    // border-radius: 1.563vw;
    // background: #fff;
    // margin-top: 0.521vw;
    // transition: all 0.3s;
    // border: 0.052vw solid #2b2a29;
    // box-shadow: 0px 6px 9px #00000017;
    // transition: all 0.3s;
    width: 8.656vw;
    height: 3.125vw;
    text-transform: uppercase;
    font-size: 16px;
    border-radius: 1.563vw;
    background: #fff;
    margin-top: 0.521vw;
    transition: all 0.3s;
    border: 0.052vw solid #2b2a29;
    box-shadow: 0px 0.313vw 0.469vw rgba(0, 0, 0, 0.0901960784);
    transition: all 0.3s;


    span {
      vertical-align: middle;
      display: inline-block;
      margin-left: 0.521vw;
      margin-top: -0.208vw;
    }

    img {
      width: 0.847vw;
    }
  }

  button:hover {
    background: #8c1211;
    color: #fff;
  }

  .UGMKsliderArea {

    .UGMKinner {
      margin: 0vw 1.521vw 0vw 0vw;
      position: relative;
    }

    .imgBox {
      width: 100%;
      margin: 0px auto;
      text-align: center;

      img {
        height: 552px;
        width: 420px;
      }
    }

    button {
      box-shadow: none;
      width: 2.865vw;
      height: 2.865vw;
      
      border-radius: 50%;
    }
    .react-multiple-carousel__arrow::before{
      color: #111111;
    }

    .slick-prev,
    .slick-next {
      z-index: 1;
    }
    .slick-next {
      right: -1.875vw;
    }

    .slick-prev:before,
    .slick-next:before {
      font-size: 2.135vw !important;
     
    }

    .UGMKinner::after {
      content: "";
      position: absolute;
      display: block;
      left: 0;
      right: 0;
      background: #f6efe2;
      width: 100%;
      height: 58vh;
      top: 3.865vw;
      z-index: -1;
    }

    p {
      color: #7e7e7e;
      font-size: 24px;
      line-height: normal;
      padding-right: 20px;
    }

    .sliderTitle {
      padding-top: 2.042vw;
      margin: 0vw 1.521vw 0vw 0vw;
      h4 {
        font-size: 1.875vw;
        color: #1e1e1e;
        padding: 30px 0px 5px;
      }
    }
    .slick-prev:before {
      background: url(../../Assets/img/smallLeftArrow.svg) no-repeat;
      width: 1.823vw;
      height: 1.823vw;
      content: "";
      display: block;
    }
    .slick-next:before {
      background: url(../../Assets/img/smallarrowRight.svg) no-repeat;
      width: 1.823vw;
      height: 1.823vw;
      content: "";
      display: block;
    }
    .UGMKslider .slick-next {
      right: 1.042vw;
      top: 1.927vw;
    }
    .UGMKslider .slick-next {
      right: 0px;
      top: 1.927vw;
    }
  }
}

.howItWorks {
  background: #faf6f0;
  padding: 9.167vw 0px 3.167vw;
  position: relative;

  .UGMKslider {
    display: none;

    button.UGMKsliderBtn {
      display: none;
      width: 7.656vw;
      height: 3.125vw;
      text-transform: uppercase;
      font-size: 0.729vw;
      border-radius: 1.563vw;
      background: #fff;
      margin-top: 0.521vw;
      transition: all 0.3s;
      border: 0.052vw solid #2b2a29;
      box-shadow: 0px 0.313vw 0.469vw #00000017;

      span {
        vertical-align: middle;
        display: inline-block;
        margin-left: 0.26vw;
        margin-top: -0.208vw;
      }

      img {
        width: 1.146vw;
      }
    }
  }

  .leftContent {
    padding-right: 20%;

    button {
      width: 10.063vw;
      height: 3.125vw;
      line-height: 2.344vw;
      text-transform: uppercase;
      font-size: 16px;
      border-radius: 1.563vw;
      background: #fff;
      margin-top: 1.823vw;
      transition: all 0.3s;
      border: 0.052vw solid #2b2a29;
      box-shadow: 0px 0.313vw 0.469vw #00000017;
      transition: all 0.3s;

      span {
        vertical-align: middle;
        display: inline-block;
        margin-left: 0.521vw;
        margin-top: -0.208vw;
      }

      img {
        width: 0.847vw;
      }
    }

    button:hover {
      background: #8c1211;
      color: #fff;
    }

    p {
      font-size: 1.25vw;
      color: #1e1e1e;
      font-weight: 500;
    }
  }

  .stepsBox {
    li {
      list-style: none;
      display: flex;
      justify-content: left;
      align-items: center;
      padding-bottom: 3.75vw;
      column-gap: 3.125vw;
      position: relative;
      z-index: 0;
    }

    li:last-child {
      padding-bottom: 0px;
    }

    li:last-child::before {
      display: none;
    }

    li::before {
      content: "";
      position: absolute;
      display: block;
      width: 0.052vw;
      height: 6.208vw;
      background: #c5c5c5;
      // top: 5.938vw;
      // left: 69px;
      top: 5.938vw;
      left: 3.594vw;
      z-index: -1;
    }

    .stepDetail {
      width: 75%;
      padding-left: 1.302vw;

      h6 {
        font-size: 1.875vw;
        font-weight: 600;
        padding-bottom: 0.521vw;
      }

      p {
        margin: 0px;
    color: #7e7e7e;
    font-size: 1.25vw;
    font-weight: 500;
    line-height: 1.771vw;
      }
    }

    .circleBox {
      width: 8.375vw;
      height: 8.375vw;
      background: #fff;
      border-radius: 50%;
      box-shadow: 0px 0.313vw 0.625vw #00000017;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 0;
      padding: 1.563vw;
      img {
        width: 100%;
      }
    }
  }

  h3 {
    color: #d5b376;
    opacity: 0.1;
    
    font-family: "Karla", sans-serif;
    font-weight: 800;
    position: absolute;
    top: 0.781vw;
    left: 0;
  }
  
}

.UGMKArea.ourCourses {
  h3 {
    font-size: 9.375vw !important;
    top: 0px;
    left: 0;
  }
  h2{
    font-size: 6.25vw !important;
  }

  // .Cviewdesk {
  //   display: none;
  // }
}

//*************************COURSE SECTION START**************************

.CoursesOuter {
  position: relative;

  .coursesBox {
    background: #f6efe2;
    padding: 1.042vw;
    
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: scale(1);
    position: relative;
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .coursesBox::after {
    content: "";
    border-radius: 0.26vw;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0.26vw 0.781vw rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .coursesBox:hover:after {
    opacity: 1;
  }

  .coursesBox:hover {
    transform: scale(1.02, 1.02);
  }

  .countBox {
    font-weight: 600;
    position: relative;
    top: 0;
    left: 0;
    font-size: 6.25vw;
    line-height: 2.344vw;
    color: #d5b275;
  }

  .courseImg {
    position: relative;
    width: auto;
  
    bottom: 0;
    
  }

  .courseImg2 img {
    height: 29.688vw;
    width: auto;
  }

  .courseImg2 {
    position: relative;
    width: auto;
    right: -3.854vw;
    bottom: 0;
    top: -5.469vw;
  }


  .courseBottom {
    font-size: 1.875vw;
    font-weight: 600;
    color: #1e1e1e;
    padding: 2.042vw 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .rightArrow img {
    width: 3.75vw;
  }
}

.OurCoursestext {


  .ViewAll {
    text-align: right;
  }
}

.pTop {
  padding-top: 2.604vw;
}

.UGMKArea.ourCourses {

  h2 {
    line-height: 5.208vw;
  }

  .cpadR {
    padding-right: 2.604vw;
  }


  // button {
  //   display: none;
  // }
  .ViewAll.Cviewmob {
    button {
      display: none;
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1280px) {
  .homeBanner {
    .bannerContent {
      top: 17%;

      .mainHeading {
        h1 {
          line-height: 5.99vw;
          font-size: 5.938vw;
        }
        h1.spaceLeft {
          line-height: 6.51vw;
        }
      }

    
    }
  }
  .AboutArea {
    padding: 15% 0px 5.469vw 0px;
    .AboutImg {
      img {
        width: 32.552vw;
      }
    }
  
    .aboutContent {
      position: static;
      padding-top: 1.875vw;

      h2 {
        padding-bottom: 3.604vw;
      }
    }
  }
  .UGMKArea {
    padding: 8.854vw 0px 4.167vw;
    p {
      width: 88%;
    }

    h2 {
      padding-bottom: 3.604vw;
    }

  
  }
  .howItWorks {
    h3 {
      font-size: 5.573vw;
      top: 0;
    }
    h2 {
      line-height: 6.875vw;
      padding-bottom: 1.563vw;
    }
  }
  .UGMKArea.ourCourses {
    h3 {
      font-size: 5.573vw;
    }
    h2 {
      margin-top: -2.344vw !important;
    }

    .courseImg {
      left: -4.865vw;
      width: 42.32vw;
top: -3.9vw;
    }
    .courseImg2 {
      right: -1.302vw;
    }
   
  }
  .TestimonialArea {

    .SliderContent {
      .Quote {
        position: absolute;
        left: 1.042vw;
      }
      .QuoteRight {
        right: 2.083vw !important;
      }
    }
  }
}
@media screen and (min-width: 1200px) and (max-width: 1280px) {
  .aboutContent {
    top: 8.125vw !important;
  }
}
@media screen and (min-width: 1281px) and (max-width: 1365px) {
  .homeBanner {
    .bannerContent {
      top: 16%;

      .mainHeading {
        h1 {
          line-height: 7.552vw;
          font-size: 7.292vw;
        }
        h1.spaceLeft {
          line-height: 7.552vw;
        }
      }

      .bottomArea {
        width: 73%;
        padding-top: 1.302vw;
      }
    }
  }
  .AboutArea {
    padding: 7% 0px 5.469vw 0px;
    .AboutImg {
      img {
        width: 34.896vw;
      }
    }
    h3 {
      font-size: 5.625vw;
      top: 5.208vw;
    }
    .aboutContent {
      position: static;
      padding-top: 4.115vw;
      h2 {
        padding-bottom: 3.604vw;
      }
    }
  }

  .UGMKArea {
    padding: 9.375vw 0px 4.167vw;
    p {
      width: 88%;
    }

    h2 {
      padding-bottom: 3.604vw;
    }
  }
  .howItWorks {
    h3 {
      font-size: 5.573vw;
      top: 0;
    }
    h2 {
      line-height: 6.875vw;
      padding-bottom: 1.563vw;
    }
  }
  .UGMKArea.ourCourses {
    h3 {
      font-size: 5.573vw;
    }
    h2 {
      margin-top: -2.344vw !important;
    }

    .courseImg {
      left: -2.865vw;
    }
    .courseImg2 {
      right: -2.604vw;
    }
    .courseImg img {
      height: 21.719vw;
    }
  }
  .TestimonialArea {
    .testimonialHeading {
      padding: 6.25vw 0px 1.823vw;
    }
    .SliderContent {
      .Quote {
        position: absolute;
        left: 1.042vw;
      }
      .QuoteRight {
        right: 2.083vw !important;
      }
    }
  }
}

@media screen and (min-width: 1366px) and (max-width: 1499px) {
  .AboutArea {
    padding: 10% 0px 5.469vw 0px;
    .AboutImg {
      img {
        width: 36.979vw;
      }
    }
  
    .aboutContent {
      position: static;
      padding-top: 1.302vw;
      h2 {
        padding-bottom: 3.604vw;
      }
    }
  }
  .UGMKArea {


  }
  .howItWorks {
    h3 {
      font-size: 6.25vw;
      top: 0;
    }
    h2 {
      line-height: 6.875vw;
      padding-bottom: 1.563vw;
    }
  }
  .UGMKArea.ourCourses {
    h3 {
      font-size: 5.573vw;
    }
    h2 {
      margin-top: -2.344vw !important;
    }

    .courseImg {
      left: -5vw;
      width: 42.32vw;
      top: -3.5vw;
    }
    .courseImg2 {
      right: -2.604vw;
    }
    
  }
  .TestimonialArea {
    .testimonialHeading {
      padding: 6.25vw 0px 1.823vw;
    }
    .SliderContent {
      .Quote {
        position: absolute;
        left: 1.042vw;
      }
      .QuoteRight {
        right: 2.083vw !important;
      }
    }
  }
}
@media screen and (min-width: 1500px) and (max-width: 1920px) {
  .CoursesOuter .courseImg {
    width: 42.32vw;
  }
  .AboutArea {
    padding: 10% 0px 5.469vw 0px;
    .AboutImg {
      img {
        width: 41.667vw;
      }
    }

    .aboutContent {
    
      h2 {
        padding-bottom: 3.604vw;
      }
    }
  }
  .UGMKArea.ourCourses {
    h3 {
      font-size: 5.573vw;
    }
 


    .courseImg2 {
      right: -2.604vw;
    }
  
  }
  .UGMKArea.ourCourses {
    padding-bottom: 3.604vw;
  }
}
@media screen and (min-width: 1366px) and (max-width: 1599px) {
  .homeBanner {

    .bannerContent {
      top: 17%;

      .mainHeading {
        h1 {
          line-height: 7.552vw;
          font-size: 7.292vw;
        }
        h1.spaceLeft {
          line-height: 7.552vw;
        }
      }

     
    }
  }
}
@media screen and (min-width: 1600px) and (max-width: 1920px) {
  .homeBanner {
  
    .bannerContent {
      top: 20%;

      .mainHeading {
        h1 {
          line-height: 10.521vw;
          font-size: 8.906vw;
        }
        h1.spaceLeft {
          line-height: 7.552vw;
        }
      }

      .bottomArea {
        width: 60%;
        padding-top: 3.125vw;
      }
    }
  }
}
.AboutImgMobile {
  display: none;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {

  .spritualArea {
    padding: 4.688vw 0px !important;
    p {
      min-width: 100%;
    }
  }
  .UGMKArea {




    
    .UGMKpera {
      p {
        width: 100%;
      }
    }
    
    
    
  }
  
  .UGMKArea.ourCourses {
  
    .cpadR {
      padding-right: 0.521vw;
    }
    .cpadL {
      padding-left: 0.781vw;
    }
    .courseImg {
      left: -5vw !important;
     
    }
    .courseImg2 {
      left: 0;
      top: -4.688vw;
      right: 0;
      text-align: center;
      img {
        width: auto !important;
        height: 16.927vw !important;
      }
    }
  }

  .CoursesOuter {
    .coursesBox {
      max-height: 55.625vw;
    }
  }

}

@media screen and (max-width: 767px) {
  .offcanvas.offcanvas-end {
    width: 100% !important;
  }
    .CoursesOuter .courseImg {
        top: 1vw;
        left: -4vw;
        width: 96.32vw !important;
    }

  .UGMKpera p {
    font-size: 3.25vw !important;
    color: #1e1e1e;
    margin: 0px;
    font-weight: 500;
  }
  .btn-close-white {
    filter: saturate(0.5) !important;
  }
  .courseBottom {
    font-size: 3.5vh !important;
    font-weight: 600;
    color: #1e1e1e;
    padding: 2.042vw 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .homeBanner {
    // background: url(../../Assets/img/homeBanner.png) no-repeat;
    // height: 56.667vw;
    // background-size: cover;
    // position: relative;
    .bannerImg {
      img {
        display: none;
      }
    }
    .mobEnrollbtn {
      display: flex;
      z-index: 9999 !important;
      background: #8c1211;
      height: 11.083vw;
      bottom: 0px;
      position: fixed;
      width: 100%;
      line-height: 8.083vw;
      text-align: center;
      color: #fff;
      font-weight: 500;
      align-items: center;
      justify-content: center;
    }

    .bannerContent {
      width: auto;
      
      top: 20% !important;
      padding: 0px 0.781vw;
      .mainHeading {
        h1 {
          font-size: 4.167vw;
          line-height: 4.948vw;
          text-align: left;
        }
        
      }

     
    }
  }
  .AboutArea {
    padding: 20% 0px 2.604vw 0px;
    h2 {
      font-size: 2.188vw;
    }
 
    .AboutImg {
      display: none;
    }
    .AboutImgMobile {
      padding-bottom: 1.563vw;
      display: block;
    }
  }

  .UGMKArea {


    h3 {
      font-size: 2.865vw;
      top: 2.292vw;
    }

    h2 {
      font-size: 2.188vw;
      padding-bottom: 1.563vw;
    }
    .UGMKpera {
      p {
        width: 100%;
      }
    }
    .ViewAll.ViewAllMob {
      display: block;
      text-align: center;
      button {
        width: 6.042vw;
        height: 2.448vw;
        text-transform: uppercase;
        font-size: 0.729vw;
        border-radius: 1.563vw;
        background: #fff;
        margin-top: 0.521vw;
        transition: all 0.3s;
        border: 0.052vw solid #2b2a29;
        box-shadow: 0px 0.313vw 0.469vw rgba(0, 0, 0, 0.0901960784);
      }
    }

    .UGMKsliderArea {
     
      .UGMKinner {
        margin: 0px 1.042vw 0px 1.042vw;
      }
      
      button {
        width: 3.385vw;
      }
      .slick-prev {
        left: -0.469vw;
      }
      .slick-next {
        right: -1.875vw !important;
      }
    }

    .slick-next {
      right: -1.354vw;
    }
    .sliderTitle {
      padding-top: 1.042vw;
      margin: 0px 1.042vw;
    }
  }

  .howItWorks {
    .stepsBox {
      display: none;
    }
    .UGMKslider {
      display: block;

      .slick-prev {
        left: -0.729vw;
        top: 17%;
        z-index: 2;
        width: 9.563vw;
        height: 9.563vw;
      }
      .slick-next {
        right: 0px;
        top: 17%;
        width: 9.563vw;
        height: 9.563vw;
      }
    }

    .textBox {
      text-align: center;
      padding: 1.563vw 0px 0px;

      p {
        text-align: center;
        padding: 0px;
        color: #1e1e1e;
      }
    }
    .IconBox::after {
      content: "";
      display: block;
      left: 106px;
      position: absolute;
      height: 0.052vw;
      background: #a5a5a5;
      width: 6.198vw;
      top: 51%;
    }
    .IconBox::before {
      content: "";
      display: block;
      left: -6.458vw;
      position: absolute;
      height: 0.052vw;
      background: #a5a5a5;
      width: 6.406vw;
      top: 51%;
      z-index: -1;
    }
    .IconBox {
      width: 11.082vw;
      height: 11.082vw;
      background: #f6efe2;
      box-shadow: 0px 0.625vw 1.042vw #00000017;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px auto;
      border-radius: 50%;
      position: relative;

      img {
        height: 6.128vw;
      }
    }
    .UGMKslider .slick-prev:before {
      background: url(../../Assets/img/smallLeftArrow.svg) no-repeat;
        width: 4.563vw;
        height: 4.563vw;
        content: "";
        display: block;
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .UGMKslider .slick-next:before {
      background: url(../../Assets/img/smallarrowRight.svg) no-repeat;
      width: 4.563vw;
      height: 4.563vw;
      content: "";
      display: block;
      background-position: top center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    padding: 2.344vw 0px 2.604vw;
    h3 {
      font-size: 2.865vw;
    }
   
    .leftContent {
      padding-right: 0px;
    }

    .deskbtn {
      display: none;
    }

    .UGMKsliderBtn {
      display: block !important;
      margin: 0px auto;
      height: 2.448vw !important;
    }
  }
  .ViewAll.deskView {
    display: none;
  }
  .CoursesOuter.cpadR {
    padding-top: 2.604vw;
  }
  .UGMKArea.ourCourses {
    h3 {
      font-size: 1.979vw;
    }
 
    .Cviewdesk {
      display: none;
    }
    .ViewAll.Cviewmob {
      button {
        display: block;
        margin: 1.823vw auto 0px;
      }
    }
    .OurCoursestext {
      padding-bottom: 0px;
    }
  }
  .UGMKArea.ourCourses .cpadR {
    padding-right: 0;
  }
  .UGMKArea.ourCourses .cpadL {
    padding-left: 0px;
  }
  .CoursesOuter {
   
    .courseImg {
      left: -1.719vw;
      top: 0;

      img {
        height: 13.438vw;
        width: auto;
      }
    }
    .courseImg2 {
      left: 3.125vw;
      top: 1.042vw;
      bottom: 0;

      img {
        height: 14.583vw;
        width: auto;
      }
    }
  }

 
}

@media screen and (min-width: 1025px) and (max-width: 1199px) {
  .homeBanner {
    .bannerContent {
      top: 11%;
    }
  }

  .AboutArea {
    padding: 8% 0px 0px 0px;
  
    h2 {
      font-size: 5.208vw;
    }
    .AboutImg {
      img {
        width: 33.854vw;
      }
    }
    .aboutContent {
      top: 0.781vw;
    }
    button {
      height: 2.604vw;
    }
  }
  .spritualArea {
    padding: 6.688vw 0px !important;
    p {
      min-width: 100%;
    }
  }

  .howItWorks {
    padding: 4.167vw 0px 2.865vw;
    h3 {
      font-size: 5.729vw;
    }

    .leftContent {
      padding-right: 0px;

      button {
        margin-left: 0px;
      }
    }
  
  }
  .UGMKArea.ourCourses {
   
 
    .cpadR {
      padding-right: 0.521vw;
    }
    .cpadL {
      padding-left: 0.781vw;
    }
    .courseImg {
      left: 1.042vw;
      
    }
    .courseImg2 {
      left: 0;
      top: -4.688vw;
      right: 0;
      text-align: center;
      img {
        width: auto !important;
        height: 16.927vw !important;
      }
    }
  }

 

  .TestimonialArea {
    padding: 0px 0px 3.125vw !important;
    .testimonialHeading {
      padding: 3.542vw 0px 0.521vw;
    }
  }
  .navbar-toggler:focus {
    box-shadow: none !important;
  }
}

.navbar-toggler {
  padding: 0 !important; /* or set it to the value you prefer */
  font-size: inherit !important;
  line-height: inherit !important;
  color: inherit !important;
  background-color: transparent !important;
  border: none !important; /* Remove the border */
  border-radius: 0 !important;
  transition: none !important; /* Disable transition if needed */
}
