@import url("https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&display=swap");

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

img video {
  max-width: auto;
}
/* Remove autofill background color */
input:-webkit-autofill,
textarea:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #F6EFE2 inset !important; /* or your desired background color */
  box-shadow: 0 0 0 1000px #F6EFE2 inset !important; /* or your desired background color */
}

.inputBox input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.inputBox input[type='number']::-webkit-inner-spin-button,
.inputBox input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none; /* Chrome, Safari, Edge */
  margin: 0; /* Prevent margin */
}


.offcanvas-backdrop {
  display: none !important; 
  overflow-y: auto;;
} 
 

@keyframes slideInUp {
  0% {
    transform: translateY(50px); /* Start from a slightly lower position */
    opacity: 0; /* Fully transparent */
  }
  100% {
    transform: translateY(0); /* Move to the original position */
    opacity: 1; /* Fully visible */
  }
}

.slide-in {
  animation: slideInUp 1s cubic-bezier(0.65, 0, 0.35, 1) forwards; /* Smoother animation */
}
.item-name {
  position: relative;
  display: inline-block;
}

.item-name::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px; /* Adjust this for the gap */
  height: 2px; /* Height of the underline */
  background-color: #D5B376; /* Underline color */
  transform: scaleX(0); /* Initially hidden */
  transition: transform 0.3s ease;
}

.item-name:hover::after {
  transform: scaleX(1); /* Show on hover */
}

select {
  -webkit-appearance: none; 
  -moz-appearance: none; 
  appearance: none; 
  
  /* / Custom Styles / */
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

