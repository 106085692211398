section.ContactBanner {
  margin-top: -120px;
  position: relative;

  .BannerHeading {
    position: absolute;
    z-index: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: "EB Garamond", serif;
    font-size: 100px;
    font-weight: 900;
  }

  .UGMKArea {
    .ViewAll {
      text-align: right;
    }
  }

  .aboutmenu {
    position: absolute;
    top: 100px;

    ul {
      position: absolute;
      top: 0;
      left: 0;
      width: 300px;

      li {
        list-style: none;
        display: inline-block;
        color: #faf3eb;
        font-size: 14px;
      }
      a {
        padding: 0px 4px;
        color: #faf3eb;
      }
    }
    ul li:nth-child(1) a {
      color: #faf3eb7f;
    }
  }
}

.IntroductionBanner {
  background: #faf6f0;
  position: relative;
  padding: 100px 0px 50px;

  h3 {
    color: #d5b376;
    opacity: 0.1;
    font-size: 152px;
    font-family: "Karla", sans-serif;
    font-weight: 800;
    position: absolute;
    top: 23px;
  }
  .mobhead1 {
    display: none;
  }
  .introImg {
    position: absolute;
    right: 0;
    bottom: 0;
    max-width: 847px;
    height: 100%;
    img {
    
      width: 100%;
    }
  }

  .IntroductionText {
    font-size: 16px;
    color: #1e1e1e;
    font-weight: 500;
  }
}

.spritualArea {
  background: url(../../Assets/img/footerBg.png) no-repeat;
  background-size: cover;

  text-align: center;

  h2 {
    font-size: 80px;
    padding-bottom: 30px;
  }
  p {
    color: #1e1e1e;
    font-weight: 600;
    font-size: 40px;
    width: 100%;
    margin: 0px auto;
   
  }
}

.OurGurusArea {
  background: #faf6f0;
  position: relative;
  padding: 109px 0px 0px;

  h3 {
    color: #d5b376;
    opacity: 0.1;
    font-size: 140px;
    font-family: "Karla", sans-serif;
    font-weight: 800;
  }

  

  .GurusDetail.pbottom {
    padding-bottom: 60px;
  }

  .GurusDetail {
    padding-bottom: 80px;
  }

 

  .GurusContentLeft {
    h2 {
      font-size: 40px;
      font-weight: 600;
      color: #1e1e1e;
      padding-bottom: 8px;
    }


    p {
      font-size: 24px;
      color: #1e1e1e;
      margin: 0px;
      font-weight: 500;
      padding-bottom: 25px;
    }
  }
}

.awardArea {
  padding: 50px 0px;
  position: relative;
  background: #faf6f0;

  h3 {
    color: #d5b376;
    opacity: 0.1;
    font-size: 180px;
    font-family: "Karla", sans-serif;
    font-weight: 800;
    position: absolute;
    top: -30px;
  }

  h2 {
    color: #8c1211;
    font-size: 124px;
    font-family: "EB Garamond", serif;
    font-weight: 700;
    padding-bottom: 25px;
  }
}

.AwardSliderArea {
  width: 100%;
  overflow: hidden;
  padding-top: 30px;

  .AwardpTop {
    padding-top: 70px !important;
  }

  .AwardSlide {
    text-align: center;
    padding: 0px 20px;

    img {
      width: 100%;
      height: 100%;
      margin: 0px auto;
    }
    h4 {
      color: #1e1e1e;
      font-size: 24px;
      font-weight: 600;
      padding: 17px 0px;
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1280px) {
  .spritualArea {
    padding: 80px 0px;

    p {
      font-size: 35px;
    }

    h2 {
      font-size: 70px;
      padding-bottom: 30px;
    }
  }
}

@media screen and (max-width: 767px) {
  .spritualArea {
   
    padding: 0px 0px;
    h2 {
      font-size: 50px;
      padding-bottom: 15px;
    }
    p {
      width: 100%;
      font-size: 25px;
      line-height: 32px;
    }
  }

  .OurGurusArea {
    padding: 75px 0px 0px;

    .GurusImg {
      margin-top: -15px;
    }
  }

  .IntroductionBanner {
    padding: 60px 0px 44px;

    h3 {
      font-size: 46px;
      top: 42px;
    }
    .mobhead {
      display: none;
    }
    .IntroductionText {
      .heading {
        font-size: 50px;
      }
    }
    .mobhead1 {
      display: block;
      position: absolute;
      top: 65px;
      left: 15px;
    }
    .introImg {
      position: static;
      padding-top: 80px;

      img {
        width: 90%;
        margin: 0px auto;
        display: block;
      }
    }

    .IntroductionText {
      padding-top: 20px;
    }
  }

  .OurGurusArea {
    h3 {
      font-size: 70px;
      top: 40px;
    }
    .GurusContentLeft {
      padding-top: 20px;
      h2 {
        font-size: 26px;
      }
      p {
        padding-bottom: 0px;
      }
      h4 {
        padding-bottom: 15px;
      }
    }
    .GurusDetail {
      padding-bottom: 40px;
    }
  }

  .awardArea {
    padding: 0px 0px 40px;
    h3 {
      font-size: 53px;
    }
    h2 {
      font-size: 53px;
    }
    .AwardSliderArea {
      padding-top: 0px;

      .AwardSlide {
        width: 100% !important;
        img {
          width: 100%;
          height: auto;
        }
        h4 {
          font-size: 18px;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .IntroductionBanner {
    h3 {
      font-size: 90px;
    }
    .introImg {
      position: static;
      padding-top: 80px;

      img {
        width: 90%;
        margin: 0px auto;
        display: block;
      }
    }
    .heading {
      position: absolute;
      top: 65px;
      font-size: 86px;
    }

    .IntroductionText {
      padding-top: 10px;
    }
  }

  .OurGurusArea {
    padding: 80px 0px 0px;
    h3 {
      font-size: 90px;
    }
    h2 {
      font-size: 80px;
      line-height: 59px;
    }
  }



  .awardArea {
    h3 {
      font-size: 90px;
    }
    h2 {
      font-size: 80px;
    }
  }
}
