.LifeUGMKArea {
  overflow: hidden;
  padding: 59px 0px;
  background: #faf6f0;
  position: relative;
 


  .UGMKheading {
    padding-top: 38px;
    h3 {
      color: #d5b376;
      opacity: 0.1;
      font-size: 140px;
      font-family: "Karla", sans-serif;
      font-weight: 800;
      position: absolute;
      top: 4px;
      left: 0px;
    }
  }

  .UGMKslide {
    padding: 25px 15px 30px;

    iframe {
      width: 100% !important;
      box-shadow: 0px 0px 30px #00000040;
    }
  }

  .slick-prev,
  .slick-next {
    width: 107px !important;
    border-radius: 50%;
    display: flex !important;
    justify-content: center;
    transform: translate(0, -40%) !important;
    align-items: center;
    z-index: 9999;
  }
  .slick-next {
    right: 31px !important;
  }

  .slick-prev {
    left: 20px !important;
  }

  .slick-prev:before {
    background: url(../../Assets/img/preview.svg) no-repeat;
    width: 92px;
    height: 92px;
    background-size: cover;
  }
  .slick-next:before {
    background: url(../../Assets/img/next.svg) no-repeat;
    width: 92px;
    height: 92px;
    background-size: cover;
  }

  .slick-prev:before,
  .slick-next:before {
    color: transparent;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1499px) {
  .LifeUGMKArea {
    .UGMKheading {
      h3 {
        font-size: 134px;
        top: 0px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .LifeUGMKArea {
    padding: 0px 0px 59px;
    .UGMKheading {
      h3 {
        font-size: 60px;
      }
    }
    .UGMKslide {
      iframe {
        height: 200px;
      }
    }
    .slick-prev {
      justify-content: left;
    }
    .slick-prev:before {
      background: url(../../Assets/img/preview35.svg) no-repeat;
      width: 40px;
      height: 40px;
    }
    .slick-next:before {
      background: url(../../Assets/img/next35.svg) no-repeat;
      width: 40px;
      height: 40px;
    }
    .slick-next {
      justify-content: right;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .LifeUGMKArea {
    .UGMKheading {
      padding-top: 0px;
      h3 {
        font-size: 90px;
      }
    }
  }
}

.UGMKslide {
  transition: all 0.5s ease; // Smooth transition for scaling and z-index changes
}

.active-slide {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); // Optional: add shadow for center slide
}
.inactive-slide {
  opacity: 0.8; // Dim non-center slides slightly
}
