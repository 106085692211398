button:focus {
  outline: none !important;
  box-shadow: none;
}

.navbar {
  
  z-index: 1020;
}
.navbar {


  a.Logo:hover::before {
    display: none;
  }
  a.dropdown-toggle::after {
    display: none !important;
  }
 

  a:hover {
    color: #d5b376 !important;
    text-decoration: none;
  }

  a:before {
    display: block;
    content: "";
    position: absolute;
    background: #d5b376;
    width: 3.125vw;
    height: 0.104vw;
    left: 0;
    right: 0;
    top: 3.698vw;
    margin: 0px auto;
    opacity: 0;
    transition: all 0.3s;
  }

  a:hover::before {
    opacity: 1;
  }

  a.enrollNowBtn {
    background: #fff;
    color: #2b2a29 !important;
    border-radius: 1.563vw;
    margin-left: 0.781vw;
    font-size: 0.833vw !important;
    font-weight: 500;
    border: 0.052vw solid #2b2a29;
    height: 3.125vw;
    width: 7.552vw;
    text-align: center;
    line-height: 3.125vw;
    padding: 0px !important;
    text-decoration: none;
  }
  a.enrollNowBtn:before {
    display: none;
  }
  a.navbar-brand {
    padding: 0px !important;
    border: none;
  }
  .dropdown-toggle::after {
    border-top: 0px;
  }
  a.enrollNowBtn {
    background: #ffff;
    border: 0.052vw solid transparent;
    color: #2b2a29 !important;
    transition: all 0.3s;
  }
  a.enrollNowBtn:hover {
    background: #8c1211;
    color: #fff !important;
  }
  .dropdown-menu {
    border-radius: 0px;
  }
}

.customMenu .dropdown-menu {
  width: 49.33vh;
  // height: 24.72vh;
  background: #faf6f0;
  position: relative;
  padding: 1.042vw 1.042vw 1.042vw;
  margin-top: 0.37vw;
  transform: translate(-9.5vw, 4.208vw) !important;

  a:before {
    display: none;
  }

  img {
    position: absolute;
    width: 10.938vw;
    height: 90%;
    right: 0;
    bottom: 0;
  }

 
  .nav-item {
    cursor: pointer;
  }
  a:after {
    display: block;
    content: "";
    position: absolute;
    background: url(../Assets/img/redArrow.svg);
    width: 1.354vw;
    height: 0.938vw;
    top: 22px;
    right: 0;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0;
    transition: all 0.3s;
  }

  a:hover {
    background-color: transparent;
  }

  a:hover::after {
    opacity: 1;
  }
}

nav.Logo {
  cursor: pointer;
}

.newClass.header {
  background: #fff;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 99999;
}
.newClass.header a.enrollNowBtn {
  background: #8c1211;
  color: #fff !important;
}
.newClass.header a {
  color: #000 !important;
  text-decoration: none;
}

.newClass.header a:hover {
  color: #8c1211 !important;
}
.newClass.header a.enrollNowBtn:hover {
  color: #fff !important;
}
.newClass.header a:last-child::after,
.header a:last-child::after {
  opacity: 0;
}

@media screen and (min-width: 1200px) and (max-width: 1280px) {
  .navbar a {
    
    font-size: 0.938vw !important;
  }
  .customMenu .dropdown-menu img {
    position: absolute;
      width: 50% ;
    right: 0;
    bottom: 0;
  }

}

@media screen and (min-width: 1281px) and (max-width: 1365px) {
  .navbar a {
   
    font-size: 0.938vw !important;
  }
  .customMenu .dropdown-menu img {
    position: absolute;
   
    width: 50%;
    right: 0;
    bottom: 0;
  }

}

@media screen and (min-width: 1366px) and (max-width: 1440px) {
  .navbar a {
  
    font-size: 0.938vw !important;
  }
  .customMenu .dropdown-menu img {
    position: absolute;
    width: 50%;
   
    right: 0;
    bottom: 0;
  }

}

@media screen and (min-width: 1441px) and (max-width: 1600px) {
  .customMenu .dropdown-menu img {
    position: absolute;
   
  width: 50%;
    right: 0;
    bottom: 0;
  }

}

@media screen and (min-width: 1601px) and (max-width: 1920px) {
  .navbar a {
    font-size: 1.042vw !important;
  }
  .navbar a.enrollNowBtn {
    width: 8.333vw;
  }
  .customMenu .dropdown-menu img {
    position: absolute;
   
    width: 50%;
    right: 0;
    bottom: 0;
  }

}

@media screen and (max-width: 767px) {
  .offcanvas {
    background: #faf6f0 !important;

    .offcanvas-body {
      padding: 6vw;

      .navbar-nav {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        a {
          display: block;
          width: 100%;
          font-size: 6vw !important; /* Increased font size for mobile */
          padding-bottom: 4vw;
          font-weight: 600;
          line-height: 1.6;
          color: #1e1e1e !important;
          border-bottom: 2px solid #e0e0e0;
        }
      }

      a.enrollNowBtn {
        display: none; /* Hide Enroll Now button on mobile screens */
      }
    }

    .btn-close {
      font-size: 5vw;
      color: #000 !important;
      position: absolute;
      top: 20px;
      right: 20px;
      border: none;
      background: transparent;
      opacity: 1;
    }

    .offcanvas-title img {
      width: 60%;
    }

    .offcanvas.offcanvas-end {
      width: 100% !important;
    }
  }
}



@media screen and (min-width: 768px) and (max-width: 1199px) {

  .navbar-toggler:focus {
    box-shadow: none !important;
  }
  .newClass.header {
    .navbar-toggler-icon {
      filter: invert(1);
    }
  }
  .customMenu .dropdown-menu a {
    font-size: 1.042vw !important;
    font-weight: 500 !important;
  }
  .btn-close-white {
    filter: saturate(0.5) !important;
  }
  .navbar {


    a {
      color: #1e1e1e !important;
      // display: block;
      // width: 100%;
      padding: 0px !important;
    }

    .offcanvas {
      background: #faf6f0 !important;
      width: 100% !important;

      .offcanvas-body {
        .navbar-nav {
          a {
            display: block;
            width: 100%;
            line-height: 2.708vw;
            padding: 0 !important;
            border-bottom: none;
            line-height: 2.344vw;
            font-size: 6vw !important;
          }
          a.enrollNowBtn {
            display: none;
          }
        }
      }

      .btn-close {
        color: #000;
        opacity: 1;
      }
      .offcanvas-title {
        img {
          height: 3.125vw;
        }
      }
      .offcanvas.offcanvas-end {
        width: 100% !important;
      }
    }
  }
  .navbar a::before {
    opacity: 0 !important;
  }
  .navbar-toggler {
    border: none !important;
  }

  .customMenu.nav-item.dropdown {
    width: 100%;
  }
  .offcanvas .btn-close {
    color: #000;
    opacity: 1;
  }
  .header .customMenu .dropdown-menu {
    width: 20.833vw;
    position: relative !important;
    top: -4.948vw !important;
    transform: translate(0px, 102px) !important;

    a:hover::before {
      opacity: 0 !important;
    }
  }
}
 // header.scss

@media (max-width: 992px) {
  .navbar-nav .nav-link {
    font-size: 1.25rem;  // Increase text size for mobile
  }

  .navbar-nav .enrollNowBtn {
    font-size: 1.25rem;
  }

  .offcanvas-body .customMenu {
    display: none; // Hide dropdowns in the mobile view
  }
}

/* Sticky Navbar after 30% scroll */
.newClass.header {
  background-color: #fff;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 9999;
  transition: background-color 0.3s, padding 0.3s;
}

/* Customize link styles when scrolled */
.newClass.header a.enrollNowBtn {
  background-color: #8c1211;
  color: #fff !important;
}

.newClass.header a {
  color: #000 !important;
}

.newClass.header a:hover {
  color: #8c1211 !important;
}


.newClass.header a.enrollNowBtn:hover {
  color: #fff !important;
}

.newClass.header .navbar-brand img {
  height: 50px; /* Adjust as necessary */
}

/* Rest of your styles remain the same */
