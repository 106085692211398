section.ContactBanner {
  margin-top: -120px;
  position: relative;

  .BannerHeading {
    position: absolute;
    z-index: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: "EB Garamond", serif;
    font-size: 100px;
    font-weight: 900;
  }

  .aboutmenu {
    position: absolute;
    top: 100px;

    ul {
      position: absolute;
      top: 0;
      left: 0;
      width: 300px;

      li {
        list-style: none;
        display: inline-block;
        color: #faf3eb;
        font-size: 14px;
      }
      a {
        padding: 0px 4px;
        color: #faf3eb;
      }
    }
    ul li:nth-child(1) a {
      color: #faf3eb7f;
    }
  }
}

.TermuseContent {
  background: #faf6f0;
  padding: 120px 0px 100px;

  p {
    font-size: 22px;
    color: #1e1e1e;
    font-weight: 500;
    padding-bottom: 25px;
    margin: 0px;
  }
}
