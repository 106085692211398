section.ContactBanner {
  margin-top: -6.25vw;
  position: relative;

  h2.BannerHeading.bannerheadmo {
    display: none;
  }

  .BannerHeading {
    position: absolute;
    z-index: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: "EB Garamond", serif;
    font-size: 6.25vw;
    font-weight: 900;
  }

  .aboutmenu {
    position: absolute;
    top: 120px;

    ul {
      position: absolute;
      top: 0;
      left: 0;
      width: 300px;

      li {
        list-style: none;
        display: inline-block;
        color: #faf3eb;
        font-size: 14px;
      }
      a {
        padding: 0px 4px;
        color: #faf3eb;
      }
    }
    ul li:nth-child(1) a {
      color: #faf3eb7f;
    }
  }
}

.PrivacyPolicy {
  background: #faf6f0;
  padding: 120px 0px 120px;

  .PrivacyContent {
    .privacyHeading {
      color: #8c1211;
      font-size: 40px;
      font-weight: 600;
      line-height: normal;
    }

    .PrivacyRightContent {
      font-size: 22px;
      color: #1e1e1e;
      font-weight: 500;
      margin: 0px;
      padding-left: 60px;
    }
  }

  .pdBottom {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) {
  .PrivacyPolicy {
    .PrivacyContent {
      .privacyHeading {
        padding-bottom: 20px;
      }
    }
  }
  section.ContactBanner {
    margin-top: -120px;
    padding-top: 40px;

    img {
      height: 380px;
      object-fit: cover;
    }

    .bannerContent {
      h2 {
        font-size: 60px;
        top: 60px;
      }
    }

    h2.BannerHeading.bannerheadmo {
      display: flex;
    }
    h2.BannerHeading.BannerHeading2 {
      display: none;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  section.ContactBanner {
    h2.BannerHeading {
      font-size: 70px;
    }
  }

  .BannerImg {
    img {
      height: 400px;
    }
  }
}
