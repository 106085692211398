.testimonialHeading {
  text-align: center;
  padding: 0px 0px 1.823vw;
  background: #faf6f0;
  position: relative;

  h3 {
    color: #d5b376;
    opacity: 0.1;
    font-size: 9.375vw;
    font-family: "Karla", sans-serif;
    font-weight: 800;
    position: absolute;
    top: -5vw;
    left: 0;
    right: 0;
  }
  h2 {
    padding-bottom: 1.042vw;
    font-size: 6.25vw;
  }
}

.TestimonialArea {
  background: #faf6f0;
  padding: 0vw 0vw 7.813vw;

  p {
    width: 77%;
    font-size: 1.25vw;
    color: #1e1e1e;
    margin: 0px;
    font-weight: 600;
    margin: 0px auto;
    text-align: center;
    padding: 2.083vw 0px 0px;
  }

  .SliderContent {
    position: relative;

    .Quote {
      position: absolute;
      left: 1.875vw;
      img {
        width: 4.703vw;
      }
    }
    .QuoteRight {
      position: absolute;
      right: 1.875vw;
      bottom: -2.448vw;

      img {
        width: 4.703vw;
      }
    }
  }

  .slick-slider.ThubnailOuter.slick-initialized {
    width: 75%;
    margin: 0px auto;
  }
  .thumBnailImage {
    max-width: 10.417vw;
    height: 10.417vw;
    border-radius: 50% !important;
    margin: 0px auto;
    text-align: center;
    display: block !important;

    img {
      width: 6.25vw;
      border-radius: 50%;
    }

    .slick-active {
      max-width: 7.813vw;
      height: 7.813vw;

      img {
        min-width: 7.813vw !important;
      }
    }

    h4 {
      text-align: center;
      font-size: 1.042vw;
      text-transform: uppercase;
      padding: 0.521vw 0px;
    }

    .imgBox {
      display: flex;
      justify-content: center;
    }
  }
  .slick-prev:before,
  .slick-next:before {
    display: none;
  }

  .slick-slide .imgBox img {
    opacity: 0.5;
  }
  .slick-slide.slick-active.slick-center .imgBox img {
    opacity: 1;
    width: 7.813vw !important;
    min-width: 7.813vw !important;
  }
}



@media screen and (max-width: 767px) {
  nav.navbar.navbar-expand-xl.navbar-dark {
    background: transparent;
    z-index: 1020;
  }
 
  .opacity {
    margin: 0.104vw !important;
  }
  .testi_img {
    max-width: 15.813vw !important;
    max-height: 15.813vw !important;
    cursor: pointer;
    border-radius: 50%;
  }

}

//custom testimonial slider


.carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.042vw;
}


.carousel-items {
  display: flex;
  align-items: center;
}

.carousel-item {
  margin: 0 0.26vw;
  transition: transform 0.3s;
  flex: 0 0 20%;
}

.testi_img {
  max-width: 7.813vw;
  max-height: 7.813vw;
  cursor: pointer;
  border-radius: 50%;
}

.center {
  transform: scale(1.5);
  margin: 3.2rem;
  opacity: none;
}

.opacity {
  opacity: 0.7;
}

.end {
  transform: scale(0.6);
  margin: -0.781vw;
}

button {
  font-size: 1.25vw;
  padding: 0.26vw 0.521vw;
  margin: 0.521vw;
}

.description h2 {
  font-weight: 600;
  text-align: center;
  font-size: 1.875vw;
}
