@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css");

.FAQcontent {
  background: #faf6f0;
  padding: 120px 0;

  .faq-item {
    padding: 30px 0 0 0;
    border-bottom: 1px solid #878787;
  
     
    .faq-question {
      display: flex;
      justify-content: space-between;
      align-items: start;
     
     
      h4 {
        padding-bottom: 24px;
        font-size: 30px;
        font-weight: 700;
        margin: 0;
      }
    }

    .faq-answer {
      max-height: 0; // Initially collapse
      overflow: hidden;
      opacity: 0; // Start invisible
      transition: max-height 0.9s ease, opacity 0.3s ease; // Smooth transition for max-height and opacity
      
      p {
        font-size: 22px;
        color: #000; // Ensure the answer is displayed in black color
        padding-bottom: 20px;
      }
    }

    // When the FAQ is active, set max-height and opacity
    .faq-answer.active { 
    
      max-height: 300px; // Set a maximum height to allow content to show
      opacity: 1; // Make it visible
    }
  }
}

@media screen and (max-width: 768px) {
  .FAQcontent .faq-item {
    padding: 40px 0 0 0; // Adjust mobile padding
  }

  .faq-question h4 {
    font-size: 20px !important; // Set font size to 20px for mobile with !important
    font-weight: 600; // Slightly lighter weight
    padding-bottom: 10px; // Optional: Adjust spacing if needed
  }

  .faq-answer p {
    font-size: 16px !important; // More specific selector for answer text with !important
  padding-bottom: 0px !important;
  }
}  