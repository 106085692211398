@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,600;1,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500;600;700&display=swap');

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body {
  margin: 0 !important;
  padding: 0;
  box-sizing: border-box;
  font-family: "Karla", sans-serif !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(0.521vw + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 !important;
  padding: 0;
}

ul,
p {
  margin: 0;
  padding: 0;
}

img {
  width: 100%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.heading {
  color: #8c1211;
  font-size: 6.25vw;
  font-family: "EB Garamond", serif;
  font-weight: 700;
  padding-bottom: 2.083vw;
}
@media screen and (min-width: 375px) and (max-width: 789px) {
  .heading {
    color: #8c1211;
    font-size: 10vw !important;
    font-family: "EB Garamond", serif;
    font-weight: 700;
    padding-bottom: 2.083vw;
  }

}

@media screen and (min-width: 1280px) and (max-width: 1499px) {


  .howItWorks {
    h2 {
      line-height: 4.792vw;
    }
  }
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1640px !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1280px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px !important;
  }
  .heading {
    font-size: 110px;
    line-height: 76px;
  }
}
@media screen and (min-width: 1281px) and (max-width: 1365px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1200px !important;
  }
}
@media screen and (min-width: 1366px) and (max-width: 1440px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1280px !important;
  }
}
@media screen and (min-width: 1441px) and (max-width: 1599px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1300px !important;
  }
}
@media screen and (min-width: 1600px) and (max-width: 1920px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1440px !important;
  }
}

.navbar-light .navbar-toggler-icon {
  background: url(./Assets/img/menu.svg) no-repeat !important;
}

.bg-secondary {
  background-color: rgb(125 108 108) !important;
}

@media screen and (max-width: 767px) {
  .heading {
    font-size: 2.604vw;
    padding-bottom: 1.042vw;
  }
}
